import axios from 'axios';
import { getItem } from './localStorage';
import {env} from '../environments/environment'

const urlApi = env;

const token = getItem('authtoken')

export function getAllOrders(){
    return axios.get(`${urlApi}/orders/`, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    })
}

export function getAllOrdersByDay(){
    return axios.get(`${urlApi}/orders/all`, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    })
}

export function createOrder(order){
    return axios.post(`${urlApi}/orders/create`, order, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    })
}

export function getOrderById(id){
    return axios.get(`${urlApi}/orders/` + id, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    });
}

export function updateOrder(id, order){
    return axios.put(`${urlApi}/orders/` +id, order, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    });
}

export function deleteOrder(id){
    return axios.delete(`${urlApi}/orders/remove/` + id, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    });
}