import React, { useContext, useEffect, useState } from 'react'
import articleIcon from '../../assets/image/products.png'
import './inputModal.css'
import { ToastContext } from '../../utils/contexts/context';
import icon_ok from '../../assets/image/icon_ok.png'
import icon_cancel from '../../assets/image/icon_cancel.png'

function InputModal(props) {

    const [toast, setToast] = useContext(ToastContext);

    const [qte, setQte] = useState(1);
    const [qteDetail, setQteDetail] = useState(0);

    const [typeVente, setTypeVente] = useState(
        props.modal.IsFollow ? (props.modal.itemsPerSet>0 ? 'both' : 'wholesale') : 'simple'
        )

    const addToCart=(articleId, nom, description, image, variante, prixVente, prixDetail, itemsPerSet, typeVente)=>{

        const CartAjouter = props.cart.find((item)=>item.nom === nom && item.prixVente === prixVente && item.prixDetail === prixDetail && item.variante === variante && item.itemsPerSet === itemsPerSet && item.typeVente === typeVente)
          if(CartAjouter){
              const FiltreCartAjouter = props.cart.filter((item)=>item.nom !== nom || item.prixVente !== prixVente || item.prixDetail !== prixDetail || item.variante !== variante || item.itemsPerSet !== itemsPerSet || item.typeVente !== typeVente)
              props.updateCart([
                  ...FiltreCartAjouter,
                  {articleId, nom, description, image, variante, prixVente, prixDetail, itemsPerSet, typeVente, quantity: parseInt(CartAjouter.quantity) + parseInt(qte), quantityDetail: parseInt(CartAjouter.quantityDetail) + parseInt(qteDetail)}
              ])
          }else{
            props.updateCart([
                  ...props.cart,
                  {articleId, nom, description, image, variante, prixVente, prixDetail, itemsPerSet, typeVente, quantity:parseInt(qte), quantityDetail: parseInt(qteDetail)}
              ])
          }
    }


    const handleAdd = (e) =>{
        e.preventDefault()
        if(props.modal.IsFollow && typeVente === "wholesale"){
            // On verifie la quantité demandée pour les ventes en gros
            if(props.modal.inStock < qte){
                setToast({toast, isOpen:true, isSuccess: false, message: "Par rapport au stock, c'est trop", icon:icon_cancel})
            }else{
                addToCart(
                    props.modal.id, 
                    props.modal.nom,
                    props.modal.description, 
                    props.modal.image, 
                    props.modal.variante,
                    props.modal.prixVente,
                    props.modal.prixDetail,
                    props.modal.itemsPerSet,
                    typeVente
                    )

                props.setModal({...props.modal, isOpen: false})
            }
        }else if(props.modal.IsFollow && typeVente === "both"){
            // On verifie la quantité demandée pour les ventes en detail
            if(props.modal.inStock < qte || (props.modal.stockDetail < qteDetail && props.modal.inStock < Math.ceil(qteDetail/props.modal.itemsPerSet))){
                setToast({toast, isOpen:true, isSuccess: false, message: "Par rapport au stock, c'est trop", icon:icon_cancel})
            }else{

                addToCart(
                    props.modal.id, 
                    props.modal.nom,
                    props.modal.description, 
                    props.modal.image, 
                    props.modal.variante,
                    props.modal.prixVente,
                    props.modal.prixDetail,
                    props.modal.itemsPerSet,
                    typeVente)

                props.setModal({...props.modal, isOpen: false})
            }
        }else{
            addToCart(
                props.modal.id, 
                props.modal.nom,
                props.modal.description, 
                props.modal.image, 
                props.modal.variante,
                props.modal.prixVente,
                props.modal.prixDetail,
                props.modal.itemsPerSet,
                typeVente)

            props.setModal({...props.modal, isOpen: false})
        }
    }

  return (
    <div className='modal-container'>
        <div className="modal-input">
            <form className="form_modal">
                    <div className="header_container_modal">
                        <div className="div">
                            <img src={articleIcon} alt='icon'/>
                            <h4>Nombre d'article</h4>
                        </div>
                
                        {props.modal.IsFollow && <h6>{typeVente==='wholesale' ? 'Stock en gros' : (typeVente==='retail' ? 'Stock en detail': (typeVente==='both' ? 'Stock en gros et en Details: ' : 'Simple'))} 
                        : {typeVente==='wholesale' ? `${props.modal.inStock}x , ` : (typeVente==='retail' ? `${props.modal.stockDetail}x , ` : (typeVente==='both' ? (`Gros(${props.modal.inStock}x) / Details(${props.modal.stockDetail}x) , `) : ''))} 
                         Prix Unitaire : {typeVente==='wholesale' ? ` ${props.modal.prixVente} $HT` : (typeVente==='retail' ? ` ${props.modal.prixDetail} $HT` : (typeVente==='both' ? ` ${props.modal.prixVente} $HT / ${props.modal.prixDetail} $HT` : `${props.modal.prixVente} $HT`))}</h6>}
                    </div>

                    {/* {props.modal.IsFollow &&
                    <div className="input_form_modal">
                        <label>Type de vente</label>
                        <select value={typeVente} onChange={(e)=> setTypeVente(e.target.value)} required>
                            <option>Choisir le type de vente</option>
                            <option value='wholesale'>Vente en gros</option>
                            {props.modal.itemsPerSet>0 && <option value='retail'>Vente au detail</option>}
                            {props.modal.itemsPerSet>0 && <option value='both'>En gros et en détails</option>}
                        </select>
                    </div>} */}

                    <div className="input_form_modal">
                        <label>Quantité</label>
                        <input type='number' value={qte} onChange={(e)=> setQte(e.target.value)} placeholder="Saisie le nombre d'article" required/>
                    </div>

                    {typeVente==='both' &&
                    <div className="input_form_modal">
                        <label>Quantité détails</label>
                        <input type='number' value={qteDetail} onChange={(e)=> setQteDetail(e.target.value)} placeholder="Saisie le nombre d'article en détails" required/>
                    </div>}

                    <div className="button_valide_modal">
                        <button onClick={()=> props.setModal({...props.modal, isOpen: false})}>Annuler</button>
                        <button onClick={(e)=>handleAdd(e)}>Ajouter</button>
                    </div>
            </form>
        </div>
    </div>
  )
}

export default InputModal