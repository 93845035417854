import React, { useEffect } from 'react'
import './toast.css'

function Toast({toast, setToast}) {
    useEffect(()=>{
        const timeout = setTimeout(()=>{
            setToast({...toast, isOpen: false})
        },3000)
        return()=>{
            clearTimeout(timeout)
        }
    }, [toast, setToast])

    return toast.isOpen ?(
        <div className='toast-container'>
            <div className={toast.isSuccess===true ? "container_theme_succes" : "container_theme_echec"}>
                <img src={toast.icon} alt='icon' style={{width: '35px'}}/>
                <div className='theme'>{toast.message}</div>
                <button onClick={()=>setToast({...toast, isOpen: false})} className='toast-button'>X</button>
            </div>
        </div>
    ): <></>
}

export default Toast