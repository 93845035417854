import React, { useEffect, useState } from 'react'
import './cart.css'
import panier from '../../assets/image/delete_1.png'
import Payment from '../payment/payment';
import { getItem } from '../../utils/services/localStorage';
import { getUserById } from '../../utils/services/UserService';

function Cart({cart, updateCart}) {
    
    const [openPayment, setOpenPayment] = useState(false);

    const [salesPerson, setSalesPerson]= useState({})
    useEffect(()=>{
        getUserById(getItem('userId'))
        .then((resp)=>{
            if(resp.data){
                setSalesPerson({
                    nom:resp.data.nom,
                    prenom:resp.data.prenom,
                    username:resp.data.username,
                    email:resp.data.email,
                    tel:resp.data.tel,
                    role:resp.data.role.nom,
                    image:resp.data.imageUrl
                })
            }
        }).catch((error)=>{
            console.log(error)
        })
    },[setSalesPerson])

    // Date vente 
    const today= new Date();
    const date=new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(today);


    // ****** Les methodes permettent de d'ajouter des valeur à Order, avant de l'enregistrer dans la table commande**********
    // Methode pour faire la somme des items du cart
    const totalVente= cart.reduce(
        (acc, productType) => acc + ((productType.quantity * productType.prixVente)+(productType.quantityDetail * productType.prixDetail)),
    0)

    const totalAmount= cart.reduce(
        (acc, productType) => acc + productType.quantity + productType.quantityDetail,
    0)

    function remove(nom, variante, prixVente, typeVente){
        const FiltreCart = cart.filter((item)=> item.nom !== nom || item.prixVente !== prixVente || item.variante !== variante || item.typeVente !== typeVente)
        updateCart(FiltreCart)
    }

    // Nombre aléatoire
    const randomNumberInRange = (min, max) =>{
        return Math.floor(Math.random()
                * (max - min + 1)) + min;
    }

    const [codeVente, setCodeVente] = useState('')

    useEffect(()=>{
        setCodeVente(`C-${randomNumberInRange(1,100)}${randomNumberInRange(100,1000)}`)
    }, [openPayment, setOpenPayment])


    const handleSave=()=>{
        if(cart.length>0)
        setOpenPayment(true)
    }

  return (
    <div className='cart_container'>
        <div className="header_cart_container">

            <div className='header_cart_container_title'>
                <h4>Panier</h4>
                <img src={panier} alt='panier' onClick={()=> updateCart([])}/>
            </div>

            <div className='header_cart_container_detail'>
                <h6>Vendu par: {salesPerson.username} </h6>
                <h6>Code: {codeVente}</h6>
                <h6>Date: {date}</h6>
            </div>
        </div>

        <div className="body_cart_container">
            {cart.map((item, index)=>(
               <div key={index} className='body_cart_item'>
                    <div className='body_cart_item_cover'>
                        <img src={item.image} alt='cover'/>
                    </div>
                    <div className='body_cart_item_detail'>
                        <h6>{item.nom} {item.typeVente === 'wholesale' ? '(en gros)' : (item.typeVente === 'retail' ? '(en detail)' : (item.typeVente === 'both' ? '(en gros et en détails)' : `(${item.variante})`))}</h6>
                        <div className='body_cart_item_detail_qte'>
                            {!(item.typeVente==='retail') && (!(item.typeVente==='simple') ? <h6>Gros: x{item.quantity}</h6> : <h6>x{item.quantity}</h6>)} 
                            {item.quantityDetail!==0 && <h6>/ Details: x{item.quantityDetail}</h6>}
                        </div>
                        <div  className='body_cart_item_detail_price'>
                            <h6>{(item.prixVente*item.quantity)+(item.prixDetail*item.quantityDetail)} $HT</h6>
                            <img src={panier} alt='cover' onClick={()=> remove(item.nom, item.variante, item.prixVente, item.typeVente)}/>
                        </div>
                    </div>
               </div> 
            ))}     
        </div>

        <div className="footer_cart_container">

            <div className='footer_cart_container_price'>
                <div className='footer_cart_container_price_item'>
                    <h5>Item</h5>
                    <h6>{totalAmount}x</h6>
                </div>
                <div className='footer_cart_container_price_item'>
                    <h5>Montant total</h5>
                    <h6>{totalVente}$HT</h6>
                </div>
            </div>

            <div className='footer_cart_container_button'>
                <button onClick={()=> handleSave()}>Poursuivre la Commande</button>
            </div>
        </div>

        {openPayment && <Payment setIsOpen={setOpenPayment} cart={cart} updateCart={updateCart} totalVente={totalVente} totalAmount={totalAmount} date={date} codeVente={codeVente} salesPerson={salesPerson}/>}
    </div>
  )
}

export default Cart