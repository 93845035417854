import React, { useContext, useState } from 'react'
import articleIcon from '../../assets/image/products.png'
import './addCategorie.css'
import { createCategorie } from '../../utils/services/categorieService'
import { ToastContext } from '../../utils/contexts/context';
import icon_ok from '../../assets/image/icon_ok.png'
import icon_cancel from '../../assets/image/icon_cancel.png'

function AddCategorie(props) {

  const [toast, setToast] = useContext(ToastContext);

  const [nomCategorie, setNomCategorie] = useState('')
  const [description, setDescription] = useState('')
  const [imageUrl, setImageUrl] = useState({})

  // Methode pour upload l'image
  const handleImage=(e)=>{
    setImageUrl(e.target.files[0]);
  }

  const handleSave =(e)=>{
    e.preventDefault()
    let categorieData = new FormData()
    categorieData.append('nomCategorie',nomCategorie);
    categorieData.append('description',description);
    categorieData.append('imageUrl',imageUrl);
    createCategorie(categorieData)
      .then((resp)=>{
          if(resp.data.message){
              setToast({toast, isOpen:true, isSuccess: true, message: resp.data.message, icon:icon_ok})
              props.setIsOpen(false)
          }else{
              setToast({toast, isOpen:true, isSuccess: false, message: resp.data.errors[0].message, icon:icon_cancel})
          }
      }).catch((error)=>
          console.log(error)
      )
  }

  return (
    <div className='modal_container'>
      <div className="modal_body">

          <form className="form_modal">
                <div className="header_container_modal">
                  <div className="div">
                      <img src={articleIcon} alt='icon'/>
                      <h4>Nouvel Categorie</h4>
                  </div>
              
                  <h6>Ajouter de nouvel Categorie</h6>
                </div>

                <div className="input_form_modal">
                    <label>Nom Categorie</label>
                    <input type='text' value={nomCategorie} onChange={(e)=> setNomCategorie(e.target.value)} placeholder='Nom de la categorie' required/>
                </div>
                <div className="input_form_modal">
                    <label>Description</label>
                    <textarea value={description} onChange={(e)=> setDescription(e.target.value)} placeholder='Description de la categorie' required/>
                </div>
                <div className="input_form_modal">
                    <label htmlFor='files'>Upload image</label>
                    <input id='files' type='file' onChange={handleImage} accept=".png, .jpg, .jpeg"/>
                </div>
                <div className="button_valide_modal">
                    <button onClick={()=> props.setIsOpen(false)}>Annuler</button>
                    <button onClick={(e)=>handleSave(e)}>Sauvegarder</button>
                </div>
          </form>
      </div>
    </div>
  )
}

export default AddCategorie