import React, { useEffect, useState } from 'react'
import chevron from '../../assets/image/chevron.png'
import './sidebar.css'
import { useNavigate } from 'react-router-dom';

function SidebarItem({item}) {
    const [open, setOpen] = useState(false);

    const navigate = useNavigate()

    const [currentRoute, setCurrentRoute]= useState()

    useEffect(()=>{
        const path= window.location.pathname.toLocaleLowerCase();
        setCurrentRoute(path)
    },[])

  return item.childrens ? (
    <div className={open ? "sidebar-item open" : "sidebar-item"}>
        <div className="sidebar-title" onClick={() => setOpen(!open)}>
            <span className='group_menu'>
                <img src={item.icon} alt='icon_menu' className={currentRoute === item.path ? "icon_menu_active" : "icon_menu"}/>
                <h1 className={currentRoute === item.path ? "title_menu_active" : "title_menu"}>{item.title}</h1>   
            </span> 
            <img src={chevron} alt='chevron' className='toggle-btn' onClick={() => setOpen(!open)}/>
        </div>
        <div className="sidebar-content">
            { item.childrens.map((child, index) => <SidebarItem key={index} item={child} />) }
        </div>
    </div>
  ) : (
    <div onClick={()=> navigate(item.path)} className="sidebar-item plain">
        <div className="sidebar-title">
            <span className='group_menu'>
                <img src={item.icon} alt='icon_menu' className={currentRoute === item.path ? "icon_menu_active" : "icon_menu"}/>
                <h1 className={currentRoute === item.path ? "title_menu_active" : "title_menu"}>{item.title}</h1> 
            </span> 
        </div>
    </div>
  )
}

export default SidebarItem