import './modal.css'

function Modal(props){
    return props.dialog.isOpen ? (
        <div className='modal-container'>
            <div className='modal'>
                {props.dialog.icon ?
                    <img src={props.dialog.icon} alt='icon' style={{width:'40px'}}/>
                    : <></>
                }
                <h4>{props.dialog.title}</h4>
                <p>{props.dialog.message}</p>
                <div className='modal-button-group'>
                    <button onClick={()=>props.onDialog(false)} className='model-no'>Cancel</button>
                    <button onClick={()=>props.onDialog(true)} className='model-yes'>Confirmer</button>
                </div>
            </div>
        </div>
    ) : (<></>)
}
export default Modal