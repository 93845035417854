import React from "react"
import elshadai from '../../assets/image/el_shadai.jpg'
import './printer.css'
import dateFormat from 'dateformat';

export const ComponentToPrint = React.forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      
      <div className="header_print">
        
        <img src={elshadai} alt="elshadai" style={{width:'70px'}}/>
        <h6 className="title">El shadai</h6>
        <h6>Dieu Merci</h6>
        <h6> 25, Ave Lafond, Petit-Goâve, Haiti (W.I)</h6>
        <h6>Date: {dateFormat(props.date)}</h6>
        <h6>No: {props.codeVente}</h6>
        <h6>Client: {props.customer}</h6>
        <h6>Caissier(ère): {props.salesPerson}</h6>
        <h6>Tel: (+509) 3229-3826 / 4617-4770</h6>
      </div>


      <div className="table_container_print">
        <table className="table_print">
            <thead className="thead_print">
                <tr className="tr_print">
                    <th>Articles</th>
                    <th>Qty</th>
                    <th>Prix</th>
                    <th>Total</th>
                </tr>
            </thead>
            <tbody>
                {props.cart.map((item,index)=>
                    props.inView === false
                    ?(<tr key={index} className="items_to_print">
                        <td>{item.nom}</td>
                        <td>{item.quantity}x {item.itemsPerSet>0 && <> ({item.quantityDetail}x)</>}</td>
                        <td>{item.prixVente} $HT{item.itemsPerSet>0 && <> ({item.prixDetail} $HT)</>}</td>
                        <td>{(item.quantity*item.prixVente) + (item.quantityDetail*item.prixDetail)} $HT</td>   
                    </tr>):(
                      <tr key={index} className="items_to_print">
                        <td>{item.nomArticle}</td>
                        <td>{item.orderArticle.quantity}x {item.itemsPerSet>0 && <> ({item.orderArticle.quantityDetail}x)</>}</td>
                        <td>{item.prixVente} $HT{item.itemsPerSet>0 && <> ({item.prixDetail} $HT)</>}</td>
                        <td>{(item.prixDetail*item.orderArticle.quantityDetail) + (item.prixVente*item.orderArticle.quantity)} $HT</td>   
                    	</tr>
                    )
                )}
            </tbody>
        </table>
        
      </div>

      <div className="total_print">
        <h6>Montant versé: {props.montantVerser} $HT</h6>
        {props.discount>0 && <h6>Discount: {props.discount} $HT</h6>}
        <h6>Rendu de monnaie: {props.monnaie} $HT</h6>
        <h6>Balance: {props.balance} $HT</h6>
        <h6>Total vente: {props.totalVente} $HT</h6>
        {/* <h6>Total: {props.totalVente - props.discount} $HT</h6> */}
      </div>

      <div className="signature_print">
        {/* <div></div> */}
        <h6>Merci d'avoir choisi El shadai Dieu merci, A bientot!</h6>
      </div>
      <div></div>
    </div>
  );
});