import React, { useContext, useEffect, useState } from 'react'
import articleIcon from '../../assets/image/products.png'
import './addArticle.css'
import { useNavigate, useParams } from 'react-router-dom';
import { getAllCategories } from '../../utils/services/categorieService';
import { createArticle, getArticleById, updateArticle } from '../../utils/services/articleService';
import { ToastContext } from '../../utils/contexts/context';
import icon_ok from '../../assets/image/icon_ok.png'
import icon_cancel from '../../assets/image/icon_cancel.png'
import AddCategorie from '../../components/addCategorie/addCategorie';
import { getUserById } from '../../utils/services/UserService';
import { getItem } from '../../utils/services/localStorage';

function AddArticle() {

    // ***********  Pour gerer les authorizations  ***********
  const [privileges, setPrivileges] = useState([])

  const getUser = () =>{
    getUserById(getItem('userId'))
    .then((resp)=>{
      setPrivileges(resp.data.role.privileges)
    }).catch((error)=>{
      console.log(error)
    })
  }

  let onePrivilege ={}

  for(let privilege of privileges){
    if(privilege.menu === "Gestion Articles"){
      onePrivilege = { 
                  'haveView' :privilege.haveView,
                  'haveAdd': privilege.haveAdd,
                  'haveEdit':privilege.haveEdit,
                  'haveDelete':privilege.haveDelete
                }
    }
  }

    const [toast, setToast] = useContext(ToastContext);

    const [modalCategorie, setModalCategorie] = useState(false);

    const id_params = useParams();

    const navigate = useNavigate();

    // La liste des categories
    const [categories, setCategories] = useState([]);
    useEffect(()=>{
        getUser();
    },[]);
    
    useEffect(()=>{
        getCategories()
    },[modalCategorie])
    
    const getCategories = () =>{
        getAllCategories()
        .then((resp)=> {
            setCategories(resp.data)
          })
          .catch((error)=>{
              console.log(error)
          })
    }
    // Debut des states de l'article
    const [nomArticle, setNomArticle] = useState('');
    const [categoryId, setCategoryId] = useState();
    const [variante, setVariante] = useState('');
    const [description, setDescription] = useState('');
    const [suiviStock, setSuiviStock] = useState(false);
    const [enStock, setEnStock] = useState(0);
    const [stockDetail, setStockDetail] = useState(0);
    const [itemsPerSet, setItemPerSet] = useState(0);
    const [stockFaible, setStockFaible] = useState(0);
    const [enVente, setEnVente] = useState(false);
    const [typeProduit, setTypeProduit] = useState('simple');
    const [prixVente, setPrixVente] = useState(0);
    const [prixDetail, setPrixDetail] = useState(0);
    const [prixAchat, setPrixAchat] = useState(0);
    const [image, setImage] = useState({});


    // Methode pour upload l'image
    const handleImage=(e)=>{
        setImage(e.target.files[0]);
    }

    // Si on fait appel a ce composant pour une modification
    useEffect(()=>{
        getArticle()
    },[])

    const getArticle=()=>{
        if(id_params.id>0){
            getArticleById(id_params.id)
            .then((resp)=>{
                setNomArticle(resp.data.nomArticle)
                setCategoryId(resp.data.categoryId)
                setVariante(resp.data.variante)
                setDescription(resp.data.description)
                setSuiviStock(resp.data.suiviStock)
                setEnStock(resp.data.enStock)
                setStockDetail(resp.data.stockDetail)
                setItemPerSet(resp.data.itemsPerSet)
                setStockFaible(resp.data.stockFaible)
                setEnVente(resp.data.enVente)
                setTypeProduit(resp.data.typeProduit)
                setPrixVente(resp.data.prixVente)
                setPrixDetail(resp.data.prixDetail)
                setPrixAchat(resp.data.prixAchat)
                setImage(resp.data.imageUrl)
            }).catch((error)=>{
                console.log(error)
            })
        }
        
    }

    const handleSave = (e) =>{

        e.preventDefault();
        let dataArticle = new FormData()
        dataArticle.append('nomArticle',nomArticle);
        dataArticle.append('categoryId',categoryId);
        dataArticle.append('variante',variante);
        dataArticle.append('description',description);
        dataArticle.append('enVente',enVente);
        dataArticle.append('suiviStock',suiviStock);
        dataArticle.append('enStock',enStock);
        dataArticle.append('stockDetail',stockDetail);
        dataArticle.append('itemsPerSet',itemsPerSet);
        dataArticle.append('stockFaible',stockFaible);
        dataArticle.append('typeProduit',typeProduit);
        dataArticle.append('prixVente',prixVente);
        dataArticle.append('prixDetail',prixDetail);
        dataArticle.append('prixAchat',prixAchat);
        dataArticle.append('imageUrl',image);
        // dataArticle.append('dateCreation',date);
        if(id_params.id>0){
            updateArticle(id_params.id, dataArticle)
            .then((resp)=>{
                if(resp.data.message){
                    setToast({toast, isOpen:true, isSuccess: true, message: resp.data.message, icon:icon_ok})
                    navigate('/articles')
                }else{
                    setToast({toast, isOpen:true, isSuccess: false, message: "Modification echoué", icon:icon_cancel})
                }
            }).catch((error)=>
                setToast({toast, isOpen:true, isSuccess: false, message: error, icon:icon_cancel})
            ) 
        }else{
            createArticle(dataArticle)
            .then((resp)=>{
                if(resp.data.message){
                    setToast({toast, isOpen:true, isSuccess: true, message: resp.data.message, icon:icon_ok})
                    navigate('/articles')
                }else{
                    setToast({toast, isOpen:true, isSuccess: false, message: "Enregistrement echoué", icon:icon_cancel})
                }
            }).catch((error)=>
                console.log(error)
            )
        }
    }

    const handleAddCategorie = ()=>{
        setModalCategorie(true)   
    }

    useEffect(()=>{
        if(onePrivilege.haveAdd===false){
            navigate('/')
        }
    },[privileges])
 
  return onePrivilege.haveAdd ? 
  (
    <div className='article_container'>

        <div className="header_container">
            <div className="div">
                <img src={articleIcon} alt='icon'/>
                <h4>{id_params.id>0 ? "Mise à Jour" : 'Nouvel Article'}</h4>
            </div>
        
            <h6>{id_params.id>0 ? "Modifier les propriétés d'un article" : "Ajouter de nouvel article"}</h6>
        </div>

        <div className="body_form">

            <form className="form_container" onSubmit={handleSave}>

                <div className="inputs_form_container">
                        <div className="input_form">
                            <label>Nom Article</label>
                            <input type='text' value={nomArticle} placeholder='Titre' onChange={(e)=> setNomArticle(e.target.value)} required/>
                        </div>
                        <div className="input_form_more">
                            <label>Categorie</label>
                            <div className="input_form_more_group">
                                <select value={categoryId} onChange={(e)=> setCategoryId(e.target.value)} required>
                                    <option>Choisir le categorie</option>
                                    {categories.map((item,index)=>(
                                        <option key={index} value={item.id}>{item.nomCategorie}</option>
                                    ))}
                                </select>
                                <div className="input_form_more_button" onClick={()=>handleAddCategorie()}>
                                    +
                                </div>
                            </div>
                        </div>

                        <div className="input_form">
                            <label>Variantes</label>
                            <input type='text' value={variante} onChange={(e)=> setVariante(e.target.value)} placeholder="Pour les tailles, des poids, ..."/>
                        </div>

                        <div className="input_form">
                            <label>Description</label>
                            <textarea placeholder='Desciption' value={description} onChange={(e)=> setDescription(e.target.value)} required/>
                        </div>

                        <div className="input_form">
                            <div className='input_form_checkbox_on_sale'>
                                <input type='checkbox' id='en_vente' checked={enVente} onChange={(e) => setEnVente(e.target.checked)}/>
                                <label htmlFor='en_vente'>L'article est disponible pour la vente</label>
                            </div>  
                        </div>

                        <div className="input_form">
                                <div className='input_form_checkbox_stock'>

                                    <div className='input_form_checkbox_choice'>
                                        <div>
                                            <input type='checkbox' id='stock' checked={suiviStock} onChange={(e)=> setSuiviStock(e.target.checked)}/>
                                            <label htmlFor='stock'>Gérer stock</label>
                                        </div>
                                        <h6>Permettre la gestion des stocks</h6>    
                                    </div>
                                    
                                    {suiviStock ?
                                    (<div className='gerer_stock'>
                                        <div className="quantite_a_gerer">
                                            <label>Stock de Gros</label>
                                            <input type='number' value={enStock} onChange={(e)=> setEnStock(e.target.value)} placeholder='1'/>
                                        </div>
                                        <div className="quantite_a_gerer">
                                            <label>Détaillé par</label>
                                            <input type='number' value={itemsPerSet} onChange={(e)=> setItemPerSet(e.target.value)} placeholder='1'/>
                                        </div>
                                        <div className="stock_faible">
                                            <label>Prix de Détail</label>
                                            <input type='number' value={prixDetail} onChange={(e)=> setPrixDetail(e.target.value)} placeholder='0.0'/>
                                        </div>
                                        <div className="stock_faible">
                                            <label>Quantité alert</label>
                                            <input type='number' value={stockFaible} onChange={(e)=> setStockFaible(e.target.value)} placeholder='1'/>
                                        </div>
                                    </div>) : (<></>)}

                                </div>      

                        </div>
                        <div className="input_form">
                            <label>Type Produit</label>
                            <select value={typeProduit} onChange={(e)=> setTypeProduit(e.target.value)} required>
                                <option>Choisir le type de l'article</option>
                                <option value='simple'>Simple</option>
                                <option value='variable'>Variable</option>
                                <option value='combo'>Combo</option>
                            </select>
                        </div>
                        <div className="input_form">
                            <label>Prix de vente (en gros)</label>
                            <input type='number' value={prixVente} onChange={(e)=> setPrixVente(e.target.value)} placeholder='0.0 $HT' required/>
                        </div>
                        <div className="input_form">
                            <label>Prix d'achat  (en gros)</label>
                            <input type='number' value={prixAchat} onChange={(e)=> setPrixAchat(e.target.value)} placeholder='0.0 $HT'/>
                        </div>
                        <div className="input_form">
                            <label htmlFor='files'>Upload image</label>
                            <input id='files' type='file' onChange={handleImage} accept=".png, .jpg, .jpeg"/>
                        </div>
                </div>

                <div className="button_valide_form">
                    <button onClick={()=> navigate('/articles')}>Annuler</button>
                    <button>Sauvegarder</button>
                </div>

            </form>

        </div>


        {/* Pour afficher un categorie */}
        {modalCategorie && <AddCategorie setIsOpen={setModalCategorie}/>}
        
    </div>
  ) : (
    <></>
  )
}

export default AddArticle