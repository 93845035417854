import {Routes, Route} from 'react-router-dom';
import Home from './routes/home/home';
import Login from './pages/login/login';
import Register from './pages/register/register';
import Error from './pages/error/error';
import { AuthProvider, ToastProvider } from './utils/contexts/context';

function App() {
  
  return (
    <AuthProvider>
      <ToastProvider>
        <Routes>
          <Route path='/*' element={<Home/>}/>
          <Route path='/login' element={<Login/>}/>
          <Route path='/signup' element={<Register/>}/>
          <Route path='*' element={<Error/>}/>
        </Routes>
      </ToastProvider>
    </AuthProvider>
  );
}

export default App;
