import React, { useContext, useEffect, useState } from 'react'
import { ToastContext } from '../../utils/contexts/context';
import { useNavigate } from 'react-router-dom';
import { deleteCustomer, getAllCustomers } from '../../utils/services/customerService';
import * as XLSX from 'xlsx';
import icon_ok from '../../assets/image/icon_ok.png'
import icon_cancel from '../../assets/image/icon_cancel.png'
import deleteIcon from '../../assets/image/delete_1.png'
import editIcon from '../../assets/image/edit_1.png'
import excelIcon from '../../assets/image/excel.png'
import refreshIcon from '../../assets/image/refresh.png'
import contact_user from '../../assets/image/contacts_user.png'
import './customers.css'
import Modal from '../../components/modal/modal'
import icon_trash from '../../assets/image/trash.gif'
import search_icon from '../../assets/image/search.png'
import dateFormat from 'dateformat';

function Customers() {
  const [toast, setToast] = useContext(ToastContext);

  const [search, setSearch] = useState();
  const [keyWork, setKeyWork] = useState('');

  let navigate = useNavigate();

  // La liste des articles
  const column = ["ID", "Code Client","Type client", "Nom", "Prenom","Email","Telephone","Adresse", "Date d'enregistrement","Action"];
  const [data, setData] = useState([])

  useEffect(()=>{
    handleGetContacts();
  },[setData])

  const handleGetContacts =()=>{
      getAllCustomers()
          .then((resp)=> {
            setData(resp.data)
          })
          .catch((error)=>{
              console.log(error)
          })
  }

  // ********** Confirmation de la suppression **********
  // Dialog
  const [dialog, setDialog] = useState({
    icon: '',
    title:'',
    message: '',
    isOpen: false
}) 

// Ce qu'on doit faire selon le choix de la Boite de dialog
const [currentId, setCurrentId] = useState(0);
const confirmeDiolog = (choose) =>{
    if(choose){
        confirmeDelete()
        setDialog('', '', false)
    }else{
        setDialog('', '', false)
    }
}

// 
const confirmeDelete = () =>{
  deleteCustomer(currentId)
    .then((resp)=>{
        if(resp.data.message){
          handleGetContacts()
          setToast({toast, isOpen:true, isSuccess: true, message: resp.data.message, icon:icon_ok})
        }else{
          setToast({toast, isOpen:true, isSuccess: false, message: "Suppression echouée", icon:icon_cancel})
        }
    }).catch((error)=>{
        console.log(error)
    })
}
// **************************************************

  const handleDelete = (id) =>{
    setCurrentId(id)
      setDialog({
        icon: icon_trash,
        title:'Confirmation de suppression',
        message: 'Êtes-vous sûr(e) de vouloir supprimer?',
        isOpen:true,
      })
  }

  const handleSearch = (e) =>{
    e.preventDefault()
    setSearch(keyWork)
  }

  const refreshData = () =>{
    // handleGetArticles();
    window.location.reload();
  }

  // Export to Excel
  const handleOnExcel=()=>{
    var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.json_to_sheet(data);

    XLSX.utils.book_append_sheet(wb,ws, "MySheet1");
    XLSX.writeFile(wb, 'Liste_articles.xlsx');
  }


    const [currentPage, setCurrentPage]= useState(1)
    const [recordsPerPage, setRecordsPerPage] = useState(15);
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = data.slice(firstIndex, lastIndex)
    const npage = Math.ceil(data.length/recordsPerPage)
    const numbers = [...Array(npage + 1).keys()].slice(1)
  return (
    <div className='contact_container'>
      <div className="header_container">
        <div className="div">
          <img src={contact_user} alt='icon'/>
          <h4>Clients</h4>
        </div>
        
        <h6>Administrer vos clients</h6>
      </div>


      <div className="list_contact">

        <div className="header_list">

            <div className="header_list_body">

                <div className="header_body_left">
                  <button className='header_body_left_button_add' onClick={()=> navigate('/addCustomer')}>Ajouter</button>
                  <button><img src={refreshIcon} alt='refresh' onClick={()=> refreshData()}/></button>
                  <button><img src={excelIcon} alt='excel' onClick={()=> handleOnExcel()}/></button>
                </div>
                <div className="header_body_right"></div>

            </div>


            <div className="header_list_body">
                <div className="header_body_left">
                    <h6>Montrer</h6>
                    <form>
                        <select name='recordsPerPage' onChange={(e)=> setRecordsPerPage(e.target.value)}>
                        <option value='15'>15</option>
                          <option value='20'>20</option>
                          <option value='30'>30</option>
                          <option value='40'>40</option>
                          <option value='50'>50</option>
                        </select>
                    </form>
                    <h6>Entrées</h6>
                </div>

                <div className="header_body_right">
                    <form onSubmit={handleSearch}>
                          <input
                              value={keyWork}
                              onChange={(e)=>setKeyWork(e.target.value)}
                              placeholder='Recherche'
                          />
                          <button>
                            <img src={search_icon} alt='search'/>
                          </button>
                    </form>
                </div>

            </div>
            
        </div>
        {/* End header list */}

        <div className="content_table">
          <table>
            <thead>
              <tr>       
                  {column.map((item, index)=>(
                    <th key={index}>{item}</th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {records.map((item, index)=>
              !search || search.toLowerCase() === item.code.toLowerCase() 
              || search.toLowerCase() === item.type_client.toLowerCase()
              || search.toLowerCase() === item.nom.toLowerCase() 
              || search.toLowerCase() === item.prenom.toLowerCase()
              ?(
                <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.code}</td>
                    <td>{item.type_client}</td>
                    <td>{item.nom}</td>
                    <td>{item.prenom}</td>
                    <td>{item.email}</td>
                    <td>{item.tel}</td>
                    <td>{item.adresse}</td>
                    <td>{dateFormat(item.createdAt, "fullDate")}</td>
                    <td style={{display:'flex', gap: '5px'}}>
                      <img src={deleteIcon} alt='delete' className='delete_icon' onClick={()=>handleDelete(item.id)}/>
                      <img src={editIcon} alt='edit' className='update_icon' onClick={()=> navigate(`/updateCustomer/${item.id}`)}/>
                    </td>
                </tr>
              ) : (<tr key={index}></tr>))}
            </tbody>
          </table>
        </div>

        <div className="content_pagination">
              <ul className='pagination'>
                  <li className='page_item'>
                      <button onClick={prePage} className='page_link'>prev</button>
                  </li>
                  <div className="list_page">
                      {
                          numbers.map((n,i)=>(
                              <li key={i} className='page_item'>
                                  <button onClick={()=>changeCPage(n)} className={currentPage===n ? 'active' : 'page_link'}>{n}</button>
                              </li>
                          ))
                      }
                  </div>
                  <li className='page_item'>
                      <button onClick={nextPage} className='page_link'>next</button>
                  </li>
              </ul>
        </div>


      </div>

      {/* Modal de confirmation de suppression */}
      <Modal onDialog={confirmeDiolog} dialog={dialog} setDialog={setDialog}/>
    </div>
  )

  function prePage(){
    if(currentPage !== 1){
        setCurrentPage(currentPage - 1)
    }
  }
  function changeCPage(id){
    setCurrentPage(id)
  }

  function nextPage(){
    if(currentPage !== npage){
        setCurrentPage(currentPage + 1)
    }
  }
}

export default Customers