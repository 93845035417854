import axios from 'axios';
import { getItem } from './localStorage';
import {env} from '../environments/environment'

const urlApi = env;

const token = getItem('authtoken')

export function getAllCustomers(){
    return axios.get(`${urlApi}/customers/all`)
}

export function createCustomer(customer){
    return axios.post(`${urlApi}/customers/create`, customer, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    })
}

export function getCustomerById(id){
    return axios.get(`${urlApi}/customers/` + id, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    });
}

export function updateCustomer(id, customer){
    return axios.put(`${urlApi}/customers/` +id, customer, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    });
}

export function deleteCustomer(id){
    return axios.delete(`${urlApi}/customers/remove/` + id, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    });
}