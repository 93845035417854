import { createContext, useState } from "react";
import { hasAuthenticated } from "../services/authService";

export const AuthContext = createContext()
export function AuthProvider({children}){
    const auth = useState(hasAuthenticated)
    return(
        <AuthContext.Provider value={auth}>
            {children}
        </AuthContext.Provider>
    )
}


export const ToastContext = createContext()
export function ToastProvider({children}){
    const toast = useState({
        isOpen: false,
        isSuccess: false,
        message: '',
        icon: ''
    })
    return(
        <ToastContext.Provider value={toast}>
            {children}
        </ToastContext.Provider>
    )
}
