import axios from 'axios';
import { getItem } from './localStorage';
import {env} from '../environments/environment'

const urlApi = env;

const token = getItem('authtoken')

export function getAllExpenses(){
    return axios.get(`${urlApi}/expenses/all`, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    })
}

export function createExpense(expense){
    return axios.post(`${urlApi}/expenses/create`, expense, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    })
}

export function getExpenseById(id){
    return axios.get(`${urlApi}/expenses/` + id, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    });
}

export function updateExpense(id, expense){
    return axios.put(`${urlApi}/expenses/` +id, expense, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    });
}

export function deleteExpense(id){
    return axios.delete(`${urlApi}/expenses/remove/` + id, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    });
}