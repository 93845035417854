import React, { useContext } from 'react'
import {menu} from '../../utils/data/menu';
import SidebarItem from './sidebarItem';
import icon_menu from '../../assets/image/menu.png'
import { logout } from '../../utils/services/authService';
import { AuthContext } from '../../utils/contexts/context';
import exit_icon from '../../assets/image/exit.png' 
import { removeItem } from '../../utils/services/localStorage';

function Sidebar() {
  
  const [isAuthenticated, setIsAuthenticated] = useContext(AuthContext)

  const handleLogout=()=>{
        logout()
        setIsAuthenticated(false)
        removeItem('userId')
        window.location.reload();
    }
    
  return (
    <div className="sidebar">
        <div className='enterprise_name'>
          <h2>El Shadai</h2>
        </div>

        {menu.map((item, index) => 
        <SidebarItem key={index} item={item}/>)}
        
        <div className="sidebar_item_exit" onClick={()=> handleLogout()}>
            <span className='group_menu'>
                <img src={exit_icon} alt='icon_menu' className="icon_menu"/>
                <h1 className="title_menu">Deconnecter</h1> 
            </span> 
        </div>

    </div>
  )
}

export default Sidebar