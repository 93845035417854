import React, { useEffect, useState } from 'react'
import './header.css'
import notification_icon from '../../assets/image/notification.png'
import { getUserById } from '../../utils/services/UserService'
import { getItem} from '../../utils/services/localStorage'
import { useNavigate } from 'react-router-dom'

function Header() {

    const navigate = useNavigate();

    const [user, setUser] = useState({})

    const getUserConnected=() =>{
        getUserById(getItem('userId'))
        .then((resp)=>{
            if(resp.data){
                setUser({
                    nom:resp.data.nom,
                    prenom:resp.data.prenom,
                    username:resp.data.username,
                    email:resp.data.email,
                    tel:resp.data.tel,
                    role:resp.data.role.nom,
                    statut:resp.data.statut,
                    image:resp.data.imageUrl
                })
            }
        }).catch((error)=>{
            console.log(error)
        })
    }
    
    useEffect(()=>{
        getUserConnected()
    },[])

  return (
    <div className='header'>
        <div className="header_icon">
            {/* <img src={icon_menu} alt='menu'/> */}
        </div>
        <div className='header_group'>
            <div className='header_notidication'>
                <img src={notification_icon} alt='notification'/>
            </div>
            <div className="header_profil" onClick={()=> navigate('/profile')}>
                <img src={user.image} alt='' />
                <div className="header_profil_about">
                    <h5>{user.username}</h5>
                    <h6>{user.role}</h6>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Header