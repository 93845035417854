import axios from 'axios';
import { getItem } from './localStorage';
import {env} from '../environments/environment'

const urlApi = env;

const token = getItem('authtoken')

export function getAllCategories(){
    return axios.get(`${urlApi}/categories/all`, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    })
}

export function createCategorie(categorie){
    return axios.post(`${urlApi}/categories/create`, categorie, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    })
}

export function getCategorieById(id){
    return axios.get(`${urlApi}/categories/` + id, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    });
}

export function updateCategorie(id, categorie){
    return axios.put(`${urlApi}/categories/` +id, categorie, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    });
}

export function deleteCategorie(id){
    return axios.delete(`${urlApi}/categories/remove/` + id, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    });
}