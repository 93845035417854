import React, { useContext, useEffect, useState } from 'react'
import { ToastContext } from '../../utils/contexts/context';
import { deleteOrder, getAllOrders, getAllOrdersByDay } from '../../utils/services/orderService';
import { useNavigate } from 'react-router-dom';
import deleteIcon from '../../assets/image/delete_1.png'
import editIcon from '../../assets/image/edit_1.png'
import eye from '../../assets/image/eye.png'
import excelIcon from '../../assets/image/excel.png'
import refreshIcon from '../../assets/image/refresh.png'
import * as XLSX from 'xlsx';
import icon_ok from '../../assets/image/icon_ok.png'
import icon_cancel from '../../assets/image/icon_cancel.png'
import icon_sale from '../../assets/image/sale.png'
import './orders.css'
import { getUserById } from '../../utils/services/UserService';
import { getItem } from '../../utils/services/localStorage';
import Modal from '../../components/modal/modal';
import icon_trash from '../../assets/image/trash.gif'
import UpdateOrder from '../../components/updateOrder/updateOrder';
import dateFormat from 'dateformat';
import search_icon from '../../assets/image/search.png'

function Orders() {

  const [modalUpdateOrder, setModalUpdateOrder] = useState(false)

  const [choiceId, setChoiseId] = useState(0)

  // ***********  Pour gerer les authorizations  ***********
  const [privileges, setPrivileges] = useState([])

  const getUser = () =>{
    getUserById(getItem('userId'))
    .then((resp)=>{
      setPrivileges(resp.data.role.privileges)
    }).catch((error)=>{
      console.log(error)
    })
  }

  let onePrivilege ={}

  for(let privilege of privileges){
    if(privilege.menu === "Gestion Ventes"){
      onePrivilege = { 
                  'haveView' :privilege.haveView,
                  'haveAdd': privilege.haveAdd,
                  'haveEdit':privilege.haveEdit,
                  'haveDelete':privilege.haveDelete
                }
    }
  }

  useEffect(()=>{
    getUser();
  },[])

  const [search, setSearch] = useState("");
  const [keyWork, setKeyWork] = useState('');

  const navigate = useNavigate();

  const [toast, setToast] = useContext(ToastContext);

  const column = ["ID", "No Facture","client", "Statut Paiement", "Mode paiement", "Items", "Montant vente", "Montant en caisse", "Balance", "Discount", "Vendu par", "Date vente", "Action"];
  const [data, setData] = useState([])

  //  Pour gérer le filtrage par date des commandes
  // const [filterOption, setFilterOption] = useState('today')

  // const handleFilterChange = (e) =>{
  //   const filtre = e.target.value;
  //   setFilterOption(filtre)
  // }
  // ********************************************

  useEffect(()=>{
    handleGetOrders();
  },[setData, modalUpdateOrder])

  const handleGetOrders =()=>{
      getAllOrders()
          .then((resp)=> {
            setData(resp.data)
          })
          .catch((error)=>{
              console.log(error)
          })
  }

  // ********** Confirmation de la suppression **********
  // Dialog
  const [dialog, setDialog] = useState({
    icon: '',
    title:'',
    message: '',
    isOpen: false
  }) 

// Ce qu'on doit faire selon le choix de la Boite de dialog
const [currentId, setCurrentId] = useState(0);
const confirmeDiolog = (choose) =>{
    if(choose){
        confirmeDelete()
        setDialog('', '', false)
    }else{
        setDialog('', '', false)
    }
}

// 
const confirmeDelete = () =>{
  deleteOrder(currentId)
      .then((resp)=>{
          if(resp.data.message){
            handleGetOrders()
            setToast({toast, isOpen:true, isSuccess: true, message: resp.data.message, icon:icon_ok})
          }else{
            setToast({toast, isOpen:true, isSuccess: false, message: "Suppression echouée", icon:icon_cancel})
          }
      }).catch((error)=>{
          console.log(error)
      })
}

  const handleDelete = (id) =>{
    if(onePrivilege.haveDelete === true){
      setCurrentId(id)
      setDialog({
        icon: icon_trash,
        title:'Confirmation de suppression',
        message: 'Êtes-vous sûr(e) de vouloir supprimer?',
        isOpen:true,
      })
    }else{
      setToast({toast, isOpen:true, isSuccess: false, message: "Vous n'avez pas accès ici", icon:icon_cancel})
    }
  }

  const handleView=(id)=>{
    if(onePrivilege.haveView === true){
      navigate(`/viewOrder/${id}`)
    }else{
      setToast({toast, isOpen:true, isSuccess: false, message: "Vous n'avez pas accès ici", icon:icon_cancel})
    }
  }

  const handleUpdate=(id)=>{
    if(onePrivilege.haveEdit === true){
      setModalUpdateOrder(true)
      setChoiseId(id)
    }else{
      setToast({toast, isOpen:true, isSuccess: false, message: "Vous n'avez pas accès ici", icon:icon_cancel})
    }
  }

  const handleSearch = (e) =>{
    e.preventDefault()
    setSearch(keyWork)
  }

  const refreshData = () =>{
    window.location.reload();
  }

// Export to Excel
  const handleOnExcel=()=>{
    var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.json_to_sheet(data);

    XLSX.utils.book_append_sheet(wb,ws, "MySheet1");
    XLSX.writeFile(wb, 'Liste_orders_by_day.xlsx');
  }

  const [currentPage, setCurrentPage]= useState(1)
  const [recordsPerPage, setRecordsPerPage] = useState(100);
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = data.slice(firstIndex, lastIndex)
  const npage = Math.ceil(data.length/recordsPerPage)
  const numbers = [...Array(npage + 1).keys()].slice(1)


  useEffect(()=>{
    if(onePrivilege.haveView===false){
      navigate('/')
    }
  },[privileges])

  const [filtrage, setFiltrage]= useState('')
  const today= new Date();
  const [dateFiltre, setDateFiltre] = useState(dateFormat(today, "isoDate"));

  let totalEnCaisse = 0;
  let totalDette = 0;
  let totalVendu = 0;
  let totalMonnaie=0;
  for (let i of data) {
    if(dateFiltre===dateFormat(i.dateVente, "isoDate")){    
      totalEnCaisse =totalEnCaisse + (i.montantVerser) ;
      totalMonnaie = totalMonnaie + (i.monnaie);
      totalDette =totalDette + (i.montantDue) ;
      totalVendu =totalVendu + (i.montantVendu) ;
    }
  }

  return onePrivilege.haveView ? (
    <div className='order_container'>

      <div className="header_container">
        <div className="div">
          <img src={icon_sale} alt='icon'/>
          <h4>Liste Commandes</h4>
        </div>
        
        <h6>La liste des commandes de la journée</h6>
      </div>


      <div className="list_categories">

        <div className="header_list">

            <div className="header_list_body">

                <div className="header_body_left">
                  <button><img src={refreshIcon} alt='refresh' onClick={()=> refreshData()}/></button>
                  <button><img src={excelIcon} alt='excel' onClick={()=> handleOnExcel()}/></button>
                </div>
                <div className="header_body_right">
                  <div className="container_filter">
                    {/* Pour filter vos commandes */}
                    <form>
                      <div className="input_form">
                            <input type='date' value={dateFiltre} onChange={(e)=> setDateFiltre(e.target.value)} placeholder='Titre'/>
                        </div>
                    </form>
                    <form>
                          <select value={filtrage} onChange={(e)=> setFiltrage(e.target.value)}>
                            <option value=''>Toutes les ventes</option>
                            <option value='au comptant'>Au comptant</option>
                            <option value='a credit'>A credit</option>
                            <option value='balance'>Balance</option>
                          </select>
                    </form>
                  </div>
                </div>

            </div>


            <div className="header_list_body">
                <div className="header_body_left">
                    <h6>Montrer</h6>
                    <form>
                        <select name='recordsPerPage' onChange={(e)=> setRecordsPerPage(e.target.value)}>
                          <option value='100'>100</option>
                          <option value='150'>150</option>
                          <option value='200'>200</option>
                          <option value='300'>300</option>
                          <option value='400'>400</option>
                        </select>
                    </form>
                    <h6>Entrées</h6>
                </div>

                <div className="header_body_right">
                    <form onSubmit={handleSearch}>
                          <input
                              value={keyWork}
                              onChange={(e)=>setKeyWork(e.target.value)}
                              placeholder='Recherche'
                          />
                          <button>
                            <img src={search_icon} alt='search'/>
                          </button>
                    </form>
                </div>

            </div>
            
        </div>
        {/* End header list */}

        <div className="content_table">
          <table>
            <thead>
              <tr>       
                  {column.map((item, index)=>(
                    <th key={index}>{item}</th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {records.map((item, index)=>
              dateFiltre === dateFormat(item.dateVente, "isoDate") &&
              (!filtrage || filtrage === item.statutPayment) &&
              (!search || search.toLowerCase() === item.noFacture.toLowerCase() 
              || search.toLowerCase() === item.nomClient.toLowerCase()
              || search.toLowerCase() === item.user.username.toLowerCase())
              ?(
                <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.noFacture}</td>
                    <td>{item.nomClient}</td>
                    <td><h6 className={item.statutPayment === "a credit" ? "itemCredit" : (item.statutPayment === "balance" ? "itemBalance" : "itemComptant")}>{item.statutPayment}</h6></td>
                    <td>{item.statutPayment === "a credit" ? "Aucun" : item.modePayment}</td>
                    <td>{item.items}x</td>
                    <td>{item.montantVendu} $HT</td>
                    <td>{item.montantVerser-item.monnaie} $HT</td>
                    <td>{item.montantDue} $HT</td>
                    <td>{item.discount} $HT</td>
                    {/* <td>{item.statutExpedition}</td> */}
                    <td>{item.user.username}</td>
                    <td>{dateFormat(item.dateVente, "isoDate")}</td>
                    <td style={{display:'flex', gap: '5px'}}>
                      <img src={deleteIcon} alt='delete' className='delete_icon' onClick={()=>handleDelete(item.id)}/>
                      <img src={eye} alt='edit' className='update_icon' onClick={()=> handleView(item.id)}/>
                      {item.montantDue>0 && <img src={editIcon} alt='edit' className='update_icon' onClick={()=>handleUpdate(item.id)}/>}
                    </td>
                </tr>
              ) : (<tr key={index}></tr>))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="12">Montant total vendu</td>
                <td colSpan="1">{totalVendu} $HT</td>
              </tr>
              <tr>
                <td colSpan="12">Dette total</td>
                <td colSpan="1">{totalDette} $HT</td>
              </tr>
              <tr>
                <td colSpan="12">Montant total en caisse</td>
                <td colSpan="1">{totalEnCaisse-totalMonnaie} $HT</td>
              </tr>
            </tfoot>
          </table>
        </div>

        <div className="content_pagination">
              <ul className='pagination'>
                  <li className='page_item'>
                      <button onClick={prePage} className='page_link'>prev</button>
                  </li>
                  <div className="list_page">
                      {
                          numbers.map((n,i)=>(
                              <li key={i} className='page_item'>
                                  <button onClick={()=>changeCPage(n)} className={currentPage===n ? 'active' : 'page_link'}>{n}</button>
                              </li>
                          ))
                      }
                  </div>
                  <li className='page_item'>
                      <button onClick={nextPage} className='page_link'>next</button>
                  </li>
              </ul>
        </div>


      </div>

      {modalUpdateOrder && <UpdateOrder setIsOpen={setModalUpdateOrder} id={choiceId}/>}

      {/* Modal de confirmation de suppression */}
      <Modal onDialog={confirmeDiolog} dialog={dialog} setDialog={setDialog}/>
    </div>
  ) : (
    <></>
  )

  function prePage(){
    if(currentPage !== 1){
        setCurrentPage(currentPage - 1)
    }
  }
  function changeCPage(id){
    setCurrentPage(id)
  }

  function nextPage(){
    if(currentPage !== npage){
        setCurrentPage(currentPage + 1)
    }
  }
}

export default Orders