export function removeItem(item){
    window.localStorage.removeItem(item)
}

export function getItem(item){
    return window.localStorage.getItem(item)
}

export function addItem(localeStorageName, newItem){
    window.localStorage.setItem(localeStorageName, newItem)
}