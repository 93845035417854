import React, { useContext, useEffect } from 'react'
import {Routes, Route, useNavigate} from 'react-router-dom';
import Dashboard from '../../pages/dashboard/dashboard';
import Articles from '../../pages/articles/articles';
import Orders from '../../pages/orders/orders';
import Expenses from '../../pages/expenses/expenses';
import Categories from '../../pages/categories/categories';
import Customers from '../../pages/customers/customers';
import Suppliers from '../../pages/suppliers/suppliers';
import Users from '../../pages/users/users';
import Roles from '../../pages/roles/roles';
import Error from '../../pages/error/error';
import './home.css'
import Sidebar from '../../components/sidebar/sidebar';
import Header from '../../components/header/header';
import Pos from '../../pages/pos/pos';
import AddArticle from '../../pages/addArticle/addArticle';
import { AuthContext, ToastContext } from '../../utils/contexts/context';
import Toast from '../../components/toast/toast';
import AddExpense from '../../pages/addExpense/addExpense';
import AddCustomer from '../../pages/addCustomer/addCustomer';
import AddSupplier from '../../pages/addSupplier/addSupplier';
import AddUser from '../../pages/addUser/addUser';
import ViewOrder from '../../pages/viewOrder/viewOrder';
import RapportOrder from '../../pages/rapportOrder/rapportOrder';
import Profile from '../../pages/Profile/profile';

function Home() {
  const [toast, setToast] = useContext(ToastContext);

  const navigate = useNavigate()

  const [isAuthenticated, setIsAuthenticated] = useContext(AuthContext)

  useEffect(()=>{
    if(isAuthenticated===false){
        navigate('/login')
    }
  },[setIsAuthenticated])

  return isAuthenticated ? (
    <div className='wrapper'>
      <div className='header'>
        <Header/>
      </div>
      <div className='sidebar'>
        <Sidebar/>
      </div>
      <div className='main'>

        {/* On met le toast global ici pour pouvoir l'utiliser dans tout le systeme */}
        <Toast toast={toast} setToast={setToast}/>

        <Routes>
          <Route path='/' element={<Dashboard/>}/>
          <Route path='/articles' element={<Articles/>}/>
          <Route path='/addArticle' element={<AddArticle/>}/>
          <Route path='/updateArticle/:id' element={<AddArticle/>}/>
          <Route path='categories' element={<Categories/>}/>
          <Route path='/orders' element={<Orders/>}/>
          <Route path='/viewOrder/:id' element={<ViewOrder/>}/>
          <Route path='/pos' element={<Pos/>}/>
          <Route path='/expenses' element={<Expenses/>}/>
          <Route path='/addExpense' element={<AddExpense/>}/>
          <Route path='/updateExpense/:id' element={<AddExpense/>}/>
          <Route path='/customers' element={<Customers/>}/>
          <Route path='/addCustomer' element={<AddCustomer/>}/>
          <Route path='/updateCustomer/:id' element={<AddCustomer/>}/>
          <Route path='/suppliers' element={<Suppliers/>}/>
          <Route path='/addSupplier' element={<AddSupplier/>}/>
          <Route path='/updateSupplier/:id' element={<AddSupplier/>}/>
          <Route path='/users' element={<Users/>}/>
          <Route path='/addUser' element={<AddUser/>}/>
          <Route path='/updateUser/:id' element={<AddUser/>}/>
          <Route path='/roles' element={<Roles/>}/>
          <Route path='/OrderReports' element={<RapportOrder/>}/>
          <Route path='/profile' element={<Profile/>}/>
          <Route path='/*' element={<Error/>}/>
        </Routes>
      </div>
    </div>
  ) : (<></>)
}

export default Home