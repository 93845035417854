import React, { useEffect, useRef, useState } from 'react'
import './viewModalOrder.css'
import icon_sale from '../../assets/image/sale.png'
import { useReactToPrint } from 'react-to-print';
import { getOrderById } from '../../utils/services/orderService';
import printer from '../../assets/image/icon_print.png'
import Modal from '../modal/modal';
import { ComponentToPrint } from '../printer/printer';
import icon_back from '../../assets/image/icon_back.png'
import dateFormat from 'dateformat';

function ViewModalOrder(props) {

    const [order, setOrder] = useState({})
    const [articles, setArticles] = useState([])
    const [user, setUser] = useState({})


    const getOrder = ()=>{
        getOrderById(props.id)
        .then((resp)=>{
            if(resp.data){
                setOrder(resp.data)
                setArticles(resp.data.articles)
                setUser(resp.data.user)
            }
        }).catch((error)=>{
            console.log(error)
        })
    }

    useEffect(()=>{
        getOrder()
    },[props.id])


    // Printer
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
      content: () => componentRef.current,
  });

   // Dialog
   const [dialog, setDialog] = useState({
    icon:'',
    title:'',
    message: '',
    isOpen: false
}) 

// Ce qu'on doit faire selon le choix de la Boite de dialog
const confirmeDiolog = (choose) =>{
    if(choose){
        handlePrint()
        setDialog('', '', false)
    }else{
        setDialog('', '', false)
    }
}

  const validePrint = ()=>{
    setDialog({
      icon:printer,
      title:'Impression',
      message: 'Voulez-imprimer votre fiche?',
      isOpen:true,
    })
  }
  return props.modalView.isOpen && (
      <div className='modal-container'>
        <div className='container_view_modalOrder'>

            {/* <div className="header_container">
            <div className="div">
            <img src={icon_sale} alt='icon'/>
            <h4>Commande</h4>
            </div>

            <h6>Details sur la vente</h6>
            </div> */}

            <div className="item_order">
                <div className="header_item_order">

                    <div className="header_item_order_action">
                        <button className='header_item_order_action_add' onClick={()=> props.setModalView({...props.modalView, isOpen: false})}><img src={icon_back} alt='Imprimer' />Fermer</button>
                        <button className='header_item_order_action_printer' onClick={()=> validePrint()}><img src={printer} alt='Imprimer' />Imprimer</button>
                    </div>

                    <div className="header_item_order_body">
                        <div className='header_item_order_body_left'>
                            <h5>No Facture: {order.noFacture}</h5>
                            <h6>Statut paiement: <span className={order.statutPayment === "a credit" ? "itemCredit" : "itemComptant"}>{order.statutPayment}</span></h6>
                            <h6>Date vente: {dateFormat(order.dateVente)}</h6>
                        </div>
                    </div>

                </div>

                <div className="view_detail_order">

                    <div className="view_detail_products">
                    <div className="view_detail_products_table">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Produits</th>
                                        <th>Prix</th>
                                        <th>Quantité</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {articles.map((item, index)=>(
                                        <tr key={index}>
                                            <td>
                                                <div className="group_info">
                                                    <div className='group_info_cover'>
                                                        <img src={item.imageUrl} alt='cover'/>
                                                    </div>
                                                    <div className='group_info_about'>
                                                        <h5>{item.nomArticle}</h5>
                                                        <h6>{item.variante}</h6>
                                                    </div>
                                                </div>
                                            </td> 
                                            <td>{item.prixVente} $HT{item.itemsPerSet>0 && <>/ {item.prixDetail} $HT</>}</td>
                                            <td>{item.orderArticle.quantity}x {item.itemsPerSet>0 && <>/ {item.orderArticle.quantityDetail}x</>}</td>
                                            <td>{(item.prixDetail*item.orderArticle.quantityDetail) + (item.prixVente*item.orderArticle.quantity)} $HT</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className='footer_table'>
                                <div className="calcul_order">
                                    {/* <h6>Montant versé: {order.montantVerser} gdes</h6> */}
                                    <h6>Balance: {order.montantDue} $HT</h6>
                                    <h6>Discount: {order.discount} $HT</h6>
                                    <h6>Montant en caisse: {order.montantVerser-order.monnaie} $HT</h6>
                                    <h5>Total vente: {order.montantVendu} $HT</h5>
                                </div>
                            </div>
                    </div>
                    </div>
                    <div className="view_detail_seller">
                        <h5>Informations du personnel</h5>
                        <div className="seller_about">
                            <div className="group_info">
                                <div className='group_info_cover'>
                                    <img src={user.imageUrl} alt='cover'/>
                                </div>
                                <div className='group_info_about'>
                                    <h5>{user.nom} {user.prenom}</h5>
                                    <h6>Username: {user.username}</h6>
                                </div>
                            </div>

                            <div className="group_info_contact">
                                <h6>Email: {user.email}</h6>
                                <h6>Mobile: {user.tel}</h6>
                            </div>
                        </div>
                        <div className='customer_about'>
                            <h5>À propos du Client</h5>
                            <h6>Nom Complet: {order.nomClient}</h6>
                        </div>
                    </div>
                    <div className="view_detail_others">
                        <h5>Autres informations</h5>
                        <div>
                            <h6>Méthode de paiement: {order.statutPayment === "a credit" ? "Aucun" : order.modePayment}</h6>
                            <h6>Statut expédition: {order.statutExpedition}</h6>
                            <h6>Note de vente: {order.noteVente}</h6>
                        </div>
                    </div>

                </div>
            </div>

            {/* Pour le composant à printer */}
            <div className="printer" >
                    <ComponentToPrint 
                        ref={componentRef} 
                        cart={articles} totalVente={order.montantVendu} 
                        totalAmount={order.items} customer={order.nomClient} 
                        date={order.dateVente} codeVente={order.noFacture} 
                        salesPerson={user.username} montantVerser={order.montantVerser} 
                        monnaie={order.monnaie}
                        discount={order.discount}
                        balance={order.montantDue} inView={true}/>
            </div>

            <Modal onDialog={confirmeDiolog} dialog={dialog} setDialog={setDialog}/>
        </div>
    </div>
  )
}

export default ViewModalOrder