import React, { useEffect, useState } from 'react'
import './profile.css'
import { getProfil } from '../../utils/services/UserService';
import { getItem } from '../../utils/services/localStorage';
import search_icon from '../../assets/image/search.png'
import defaultImage from '../../assets/image/default.png'
import dateFormat from 'dateformat';
import pos from '../../assets/image/pos_terminal.png'
import article_icon from '../../assets/image/trolley.png'
import dette_icon from '../../assets/image/dette.png'
import money_icon from '../../assets/image/money_bag.png'
import eye from '../../assets/image/eye.png'
import ViewModalOrder from '../../components/viewModalOrder/viewModalOrder';

function Profile() {
  const [user, setUser] = useState({})
  const [role, setRole] = useState({})
  const [orders, setOrders] = useState([])
  const [keyWork, setKeyWork] = useState('');
  const [search, setSearch] = useState('');
  const [searchStatut, setSearchStatut] = useState('')

  useEffect(()=>{
    handleUser();
  },[setUser])

  const handleUser =()=>{
      getProfil(getItem('userId'))
          .then((resp)=> {
            setUser(resp.data)
            setOrders(resp.data.orders)
            setRole(resp.data.role)
          })
          .catch((error)=>{
              console.log(error)
          })
  }

  const handleSearch = (e) =>{
    e.preventDefault()
    setSearch(keyWork)
  }

  const [modalView, setModalView] = useState({
    isOpen: false,
    id: 0
  })

  const handleView=(id)=>{
    setModalView({isOpen:true, id: id})
  }

  const articles = orders.reduce((acc, item)=> 
  acc.includes(item.articles) ? acc : acc.concat(item.articles),
  [])

  let articlesVendu = 0;
  for (let i of articles) {
    articlesVendu =articlesVendu + (i.orderArticle.quantity) ;
  }

  const today= new Date();
  const [dateDebut, setDateDebut] = useState(dateFormat(new Date(today.getFullYear(), today.getMonth(), 1), "isoDate"));
  const [dateFin, setDateFin] = useState(dateFormat(today, "isoDate"));

  let totalVendu = 0;
  let monnaie = 0;
  let montantDu = 0;
  let montantCaisse =0;
  let nbreOrder = 0;

  for (let i of orders) {
    if(dateFormat(i.dateVente, "isoDate")>=dateDebut && dateFormat(i.dateVente, "isoDate")<=dateFin){
      monnaie = monnaie + (i.monnaie);
      montantDu = montantDu + (i.montantDue)
      montantCaisse = montantCaisse + (i.montantVerser)
      nbreOrder +=1;
    }
    totalVendu =totalVendu + (i.montantVendu) ;
  }



  const column = ['noFacture','Nom Client', 'Qty', 'Montant vendu','Montant en caisse','Balance', 'Discount', 'Statut Vente', 'Date vente', 'Action']

    const [currentPage, setCurrentPage]= useState(1)
    const [recordsPerPage, setRecordsPerPage] = useState(15);
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = orders.slice(firstIndex, lastIndex)
    const npage = Math.ceil(orders.length/recordsPerPage)
    const numbers = [...Array(npage + 1).keys()].slice(1)

    
  return (
    <div className='user_container'>
      <div className="user_container_left">
        
        <div className='header_user_container'>
            <h5>Votre Profil</h5>
            <img src={user.imageUrl=== "[object Object]" ? defaultImage : user.imageUrl} alt='cover'/>
            <h4>{user.username}</h4>
            <h5 className='header_user_container_role'>{role.nom}</h5>
        </div>

        <div className="perform_user_container">
            <div className="perform_item"><h5>{orders.length}</h5><h6>Ventes</h6></div>
            <div className="perform_item"><h5>{totalVendu} $HT</h5><h6>Mtt Vendu</h6></div>
            <div className="perform_item"><h5>{articlesVendu} </h5><h6>Produits</h6></div>
        </div>
        
        <div className='about_user_container'>
          <h5>À Propos</h5>
          <div className='about_user_item'><h5>Nom</h5> <h6>{user.nom}</h6></div>
          <div className='about_user_item'><h5>Prenom</h5> <h6>{user.prenom}</h6></div>
          <div className='about_user_item'><h5>Nom d'utilisateur</h5> <h6>{user.username}</h6></div>
          <div className='about_user_item'><h5>Email</h5> <h6>{user.email}</h6></div>
          <div className='about_user_item'><h5>Telephone</h5> <h6>{user.tel}</h6></div>
          <div className='about_user_item'><h5>Status</h5> <h6>{user.status === true ? "Actif" : "Inactif"}</h6></div>
        </div>

        
      </div>
      
      <div className='user_container_right' >
            
          <div className="apercu_container">
              <div className="card_apercu_container">
                  <div className="card_apercu_container_left">
                      <h3>{nbreOrder}</h3>
                      <h4>Commandes</h4>
                  </div>
                  <div className="card_apercu_container_right">
                      <div className="icon_card" style={{backgroundColor: '#6ad1df'}}>
                          <img src={pos} alt=''/>
                      </div>
                  </div>
              </div>

              <div className="separator"></div>

              <div className="card_apercu_container">
                  <div className="card_apercu_container_left">
                      <h3>{montantCaisse-monnaie} $HT</h3>
                      <h4>Montant en Caisse</h4>
                  </div>
                  <div className="card_apercu_container_right">
                      <div className="icon_card" style={{backgroundColor: '#6ad1df'}}>
                          <img src={money_icon} alt=''/>
                      </div>
                  </div>
              </div>

              <div className="separator"></div>

              <div className="card_apercu_container">
                  <div className="card_apercu_container_left">
                      <h3>{montantDu} $HT</h3>
                      <h4>Liste des dettes</h4>
                  </div>
                  <div className="card_apercu_container_right">
                      <div className="icon_card" style={{backgroundColor: '#6ad1df'}}>
                          <img src={dette_icon} alt=''/>
                      </div>
                  </div>
              </div>
          </div>

          <div className="user_container_right_bottom">
              <div className="user_container_right_filter">
                  <form className="search_perform" onSubmit={handleSearch}>  
                      <input
                          value={keyWork}
                          onChange={(e)=>setKeyWork(e.target.value)}
                          placeholder='Saisie le code vente'
                          className='input_perform'
                      /> 
                      <button>
                        <img src={search_icon} alt='search'/>
                      </button>
                  </form>
                  <form>
                      <input type='date' value={dateDebut} onChange={(e)=> setDateDebut(e.target.value)} placeholder='Titre' className="select_perform"/>
                      <input type='date' value={dateFin} onChange={(e)=> setDateFin(e.target.value)} placeholder='Titre' className="select_perform"/>
                      <select value={searchStatut} onChange={(e)=> setSearchStatut(e.target.value)} className="select_perform">
                            <option value=''>Toutes les ventes</option>
                            <option value='au comptant'>Au comptant</option>
                            <option value='a credit'>A credit</option>
                            <option value='balance'>Balance</option>
                      </select>
                  </form>
              </div>

              <div className="content_table_perform">
                  <table>
                      <thead>
                          <tr>
                            {column.map((item, i)=>(
                                  <th key={i}>{item}</th>
                            ))}
                          </tr>
                      </thead>
                      <tbody>
                          {                      
                            orders.map((item,index)=>
                                (!searchStatut || searchStatut === item.statutPayment)&&
                                (!search || search ===item.noFacture || search.toLowerCase() === item.nomClient.toLowerCase())&&
                                (dateFormat(item.dateVente, "isoDate")>=dateDebut && dateFormat(item.dateVente, "isoDate")<=dateFin)
                                ?(<tr key={index}>
                                    <td>{item.noFacture}</td>
                                    <td>{item.nomClient}</td>
                                    <td>{item.items}</td>
                                    <td>{item.montantVendu} $HT</td>
                                    <td>{item.montantVerser-item.monnaie} $HT</td>
                                    <td>{item.montantDue} $HT</td>
                                    <td>{item.discount} $HT</td>
                                    <td><h5 className={item.statutPayment==='au comptant' ? 'etat_sale_comptant':(item.statutPayment==='balance' ? 'etat_sale_balance' : 'etat_sale_credit')}>{item.statutPayment}</h5></td>
                                    <td>{dateFormat(item.dateVente)}</td>
                                    <td style={{display:'flex', gap: '5px'}}>
                                        <img src={eye} alt='edit' className='update_icon' onClick={()=> handleView(item.id)}/>
                                    </td>
                                </tr>):(
                                    null
                                )
                            )
                          }
                      </tbody>
                  </table>
              </div>

              <div className="content_pagination">
                <ul className='pagination'>
                    <li className='page_item'>
                        <button onClick={prePage} className='page_link'>prev</button>
                    </li>
                    <div className="list_page">
                        {/* {
                            numbers.map((n,i)=>(
                                <li key={i} className='page_item'>
                                    <button onClick={()=>changeCPage(n)} className={currentPage===n ? 'active' : 'page_link'}>{n}</button>
                                </li>
                            ))
                        } */}
                    </div>
                    <li className='page_item'>
                        <button onClick={nextPage} className='page_link'>next</button>
                    </li>
                </ul>
              </div>
          </div>
      </div>

      <ViewModalOrder modalView={modalView} setModalView={setModalView} id={modalView.id}/>
      
    </div>
  )

  function prePage(){
    if(currentPage !== 1){
        setCurrentPage(currentPage - 1)
    }
  }
  function changeCPage(id){
    setCurrentPage(id)
  }

  function nextPage(){
    if(currentPage !== npage){
        setCurrentPage(currentPage + 1)
    }
  }
}

export default Profile