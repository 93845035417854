import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext, ToastContext } from '../../utils/contexts/context'
import { login } from '../../utils/services/authService'
import icon_ok from '../../assets/image/icon_ok.png'
import icon_cancel from '../../assets/image/icon_cancel.png'
import './login.css'

function Login() {
  
    const navigate = useNavigate()

    const [isAuthenticated, setIsAuthenticated] = useContext(AuthContext)

    const [toast, setToast] = useContext(ToastContext);

    const [credentials, setCredentials] = useState({
        email: '',
        password: ''
    })

    useEffect(()=>{
        if(isAuthenticated===true){
            navigate('/')
        }
    },[setIsAuthenticated])

    const handleSubmit= async event =>{
        event.preventDefault();
        
        try{
            const response = await login(credentials);       
            if(response){
                setIsAuthenticated(response);
                navigate('/');
                setToast({toast, isOpen:true, isSuccess: true, message: "Connection reussie", icon:icon_ok})
            }else{
                setToast({toast, isOpen:true, isSuccess: false, message: "Connection echoué", icon:icon_cancel})
            }     
            
        } catch({response}) {
            alert(response)
        }
    }

  return !isAuthenticated ?(
    <div className="login_container">
            <div className="login_container_left">
                <div className="login_header">
                    <h2>EL Shadai</h2>
                    <h4>Bienvenue</h4>
                    <h5>Veuillez renseigner vos coordonnées</h5>
                </div>
                <form onSubmit={handleSubmit} className='form_container_login'>
                    <div className='form_group_login'>
                        <label className='form_label'>Email</label>
                        <input type="mail" value={credentials.email} onChange={(e)=> setCredentials({...credentials, email: e.target.value})} className='form_input_login' placeholder='@gmail.com'></input>
                    </div>
                    <div className='form_group_login'>
                        <label className='form_label'>Password</label>
                        <input type="password" value={credentials.password} onChange={(e)=> setCredentials({...credentials, password: e.target.value})} className='form_input_login' placeholder='********'></input>
                    </div>
                    <div className='form_group_button_login'>
                        <button className='form_button_login'>Connecter</button>
                    </div>    
                </form>

                <div>
                    {/* <h6>Vous n'avez pas de compte? <span onClick={()=> navigate('/signup')} style={{fontWeight: 'bold', color: '#1A8D5F', cursor:'pointer'}}>Créer un compte ici</span></h6> */}
                    <h6 style={{fontWeight: 600, marginTop: '5px'}}>Si vous n'avez pas encore de compte, </h6>
                    <h6 style={{fontWeight: 600, marginTop: '5px'}}>Contactez votre<span style={{color: '#6ad1df'}}> administrateur!</span></h6>
                </div>
            </div>
            <div className="login_container_right">
                {/* <div className="about_login_container">
                    <h4>"Ce systeme est entièrement developper par Teklik services informatiques pour
                        l'entreprise El Shadai Dieu Merci. Il vous permettra de faire la gestion de vos
                        stock, de vos ventes, et bien d'autres encore."</h4>
                </div> */}
            </div>
    </div>
  ) : (<></>)
}

export default Login