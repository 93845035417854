import React, { useContext, useEffect, useState } from 'react'
import './addRole.css'
import icon_team from '../../assets/image/team.png'
import { createRole } from '../../utils/services/roleService'
import { ToastContext } from '../../utils/contexts/context';
import icon_ok from '../../assets/image/icon_ok.png'
import icon_cancel from '../../assets/image/icon_cancel.png'

function AddRole(props) {

    const [toast, setToast] = useContext(ToastContext);

    const [nomRole, setNomRole]= useState('')

    const [allPermissions, setAllPermissions]= useState(false)

    const [permissionArticle, setPermissionArticle] = useState({
        menu: 'Gestion Articles',
        haveView: false,
        haveAdd: false,
        haveEdit: false,
        haveDelete: false
    })

    const [permissionOrder, setPermissionOrder] = useState({
        menu: 'Gestion Ventes',
        haveView: false,
        haveAdd: false,
        haveEdit: false,
        haveDelete: false
    })

    const [permissionPos, setPermissionPos] = useState({
        menu: 'pos',
        haveView: false,
        haveAdd: false,
        haveEdit: false,
        haveDelete: false
    })

    const [permissionUser, setPermissionUser] = useState({
        menu: 'Gestion utilisateurs',
        haveView: false,
        haveAdd: false,
        haveEdit: false,
        haveDelete: false
    })

    const [permissionRapport, setPermissionRapport] = useState({
        menu: 'Rapports',
        haveView: false,
        haveAdd: false,
        haveEdit: false,
        haveDelete: false
    })

    
    const updatePermissions = ()=>{
        if(allPermissions){
            setPermissionArticle({...permissionArticle, haveView: true, haveAdd: true, haveEdit: true, haveDelete: true})
            setPermissionOrder({...permissionOrder, haveView: true, haveAdd: true, haveEdit: true, haveDelete: true})
            setPermissionPos({...permissionPos, haveView: true, haveAdd: true, haveEdit: true, haveDelete: true})
            setPermissionUser({...permissionUser, haveView: true, haveAdd: true, haveEdit:  true, haveDelete: true})
            setPermissionRapport({...permissionRapport, haveView: true, haveAdd: true, haveEdit: true, haveDelete: true}) 
        }else{
            setPermissionArticle({...permissionArticle, haveView: false, haveAdd: false, haveEdit: false, haveDelete: false})
            setPermissionOrder({...permissionOrder, haveView: false, haveAdd: false, haveEdit: false, haveDelete: false})
            setPermissionPos({...permissionPos, haveView: false, haveAdd: false, haveEdit: false, haveDelete: false})
            setPermissionUser({...permissionUser, haveView: false, haveAdd: false, haveEdit:  false, haveDelete: false})
            setPermissionRapport({...permissionRapport, haveView: false, haveAdd: false, haveEdit: false, haveDelete: false})  
        }      
    }
    
    useEffect(()=>{
        updatePermissions()
    },[allPermissions])

    const handleSave =(e)=>{
        e.preventDefault()

        let role = {
            'nom': nomRole,
            'privileges': [
                permissionArticle, permissionOrder, permissionPos, permissionUser, permissionRapport
            ]
        }

        createRole(role)
        .then((resp)=>{
            if(resp.data.message){
                setToast({toast, isOpen:true, isSuccess: true, message: resp.data.message, icon:icon_ok})
                props.setIsOpen(false)
            }else{
                setToast({toast, isOpen:true, isSuccess: false, message: resp.data.errors[0].message, icon:icon_cancel})
            }
        }).catch((error)=>
            console.log(error)
        )
    }

  return (
    <div className='modal_container'>
        <div className='role_modal'>
            <form className='form_modal'>

                <div className="header_container_modal">
                    <div className="div">
                        <img src={icon_team} alt='icon'/>
                        <h4>Nouvel Role</h4>
                    </div>
                
                    <h6>Ajouter de nouvel role avec des permissions</h6>
                </div>

                <div className="input_form_modal_role">
                    <label>Nom Role</label>
                    <input type='text' value={nomRole} onChange={(e)=> setNomRole(e.target.value)} placeholder='Saisie le nom du categorie' required/>
                </div>

                <div className='group_check_form_modal_role'>
                    <h5>Permissions du role</h5>
                    
                    <div className="item_check_form_modal_role">
                        <div className="modal_menu_name">
                            <h6>Droit administrateur</h6>
                        </div>
                        <div className='modal_menu_checkbox_group'>
                            <div className="modal_menu_checkbox_item">
                                <input type='checkbox' checked={allPermissions} id='statut' onChange={(e)=> setAllPermissions(e.target.checked)}/>
                                <label htmlFor='statut'>Tout</label>
                            </div>  
                        </div>
                    </div>

                    {/* Gestion Articles */}
                    <div className="item_check_form_modal_role">
                        <div className="modal_menu_name">
                            <h6>Gestion Articles</h6>
                        </div>
                        <div className='modal_menu_checkbox_group'>
                            <div className="modal_menu_checkbox_item">
                                <input type='checkbox' checked={permissionArticle.haveView} id='statut' onChange={(e)=> setPermissionArticle({...permissionArticle, haveView: e.target.checked})}/>
                                <label htmlFor='statut'>Lire</label>
                            </div>  

                            <div className="modal_menu_checkbox_item">
                                <input type='checkbox' checked={permissionArticle.haveAdd} id='statut' onChange={(e)=> setPermissionArticle({...permissionArticle, haveAdd: e.target.checked})}/>
                                <label htmlFor='statut'>Ajouter</label>
                            </div> 

                            <div className="modal_menu_checkbox_item">
                                <input type='checkbox' checked={permissionArticle.haveEdit} id='statut' onChange={(e)=> setPermissionArticle({...permissionArticle, haveEdit: e.target.checked})}/>
                                <label htmlFor='statut'>Modifier</label>
                            </div> 

                            <div className="modal_menu_checkbox_item">
                                <input type='checkbox' checked={permissionArticle.haveDelete} id='statut' onChange={(e)=> setPermissionArticle({...permissionArticle, haveDelete: e.target.checked})}/>
                                <label htmlFor='statut'>Supprimer</label>
                            </div> 
                        </div>
                    </div>

                    {/* Gestion Vente */}
                    <div className="item_check_form_modal_role">
                        <div className="modal_menu_name">
                            <h6>Gestion Ventes</h6>
                        </div>
                        <div className='modal_menu_checkbox_group'>
                            <div className="modal_menu_checkbox_item">
                                <input type='checkbox' checked={permissionOrder.haveView} id='statut' onChange={(e)=> setPermissionOrder({...permissionOrder, haveView: e.target.checked})}/>
                                <label htmlFor='statut'>Lire</label>
                            </div>  

                            <div className="modal_menu_checkbox_item">
                                <input type='checkbox' checked={permissionOrder.haveAdd} id='statut' onChange={(e)=> setPermissionOrder({...permissionOrder, haveAdd: e.target.checked})}/>
                                <label htmlFor='statut'>Ajouter</label>
                            </div> 

                            <div className="modal_menu_checkbox_item">
                                <input type='checkbox' checked={permissionOrder.haveEdit} id='statut' onChange={(e)=> setPermissionOrder({...permissionOrder, haveEdit: e.target.checked})}/>
                                <label htmlFor='statut'>Modifier</label>
                            </div> 

                            <div className="modal_menu_checkbox_item">
                                <input type='checkbox' checked={permissionOrder.haveDelete} id='statut' onChange={(e)=> setPermissionOrder({...permissionOrder, haveDelete: e.target.checked})}/>
                                <label htmlFor='statut'>Supprimer</label>
                            </div> 
                        </div>
                    </div>

                    {/* Pos */}
                    <div className="item_check_form_modal_role">
                        <div className="modal_menu_name">
                            <h6>Points de vente</h6>
                        </div>
                        <div className='modal_menu_checkbox_group'>
                            <div className="modal_menu_checkbox_item">
                                <input type='checkbox' checked={permissionPos.haveView} id='statut' onChange={(e)=> setPermissionPos({...permissionPos, haveView: e.target.checked})}/>
                                <label htmlFor='statut'>Lire</label>
                            </div>  

                            <div className="modal_menu_checkbox_item">
                                <input type='checkbox' checked={permissionPos.haveAdd} id='statut' onChange={(e)=> setPermissionPos({...permissionPos, haveAdd: e.target.checked})}/>
                                <label htmlFor='statut'>Ajouter</label>
                            </div> 

                            <div className="modal_menu_checkbox_item">
                                <input type='checkbox' checked={permissionPos.haveEdit} id='statut' onChange={(e)=> setPermissionPos({...permissionPos, haveEdit: e.target.checked})}/>
                                <label htmlFor='statut'>Modifier</label>
                            </div> 

                            <div className="modal_menu_checkbox_item">
                                <input type='checkbox' checked={permissionPos.haveDelete} id='statut' onChange={(e)=> setPermissionPos({...permissionPos, haveDelete: e.target.checked})}/>
                                <label htmlFor='statut'>Supprimer</label>
                            </div> 
                        </div>
                    </div>

                    {/* Utilisateurs */}
                    <div className="item_check_form_modal_role">
                        <div className="modal_menu_name">
                            <h6>Gestion des utilisateurs</h6>
                        </div>
                        <div className='modal_menu_checkbox_group'>
                            <div className="modal_menu_checkbox_item">
                                <input type='checkbox' checked={permissionUser.haveView} id='statut' onChange={(e)=> setPermissionUser({...permissionUser, haveView: e.target.checked})}/>
                                <label htmlFor='statut'>Lire</label>
                            </div>  

                            <div className="modal_menu_checkbox_item">
                                <input type='checkbox' checked={permissionUser.haveAdd} id='statut' onChange={(e)=> setPermissionUser({...permissionUser, haveAdd: e.target.checked})}/>
                                <label htmlFor='statut'>Ajouter</label>
                            </div> 

                            <div className="modal_menu_checkbox_item">
                                <input type='checkbox' checked={permissionUser.haveEdit} id='statut' onChange={(e)=> setPermissionUser({...permissionUser, haveEdit: e.target.checked})}/>
                                <label htmlFor='statut'>Modifier</label>
                            </div> 

                            <div className="modal_menu_checkbox_item">
                                <input type='checkbox' checked={permissionUser.haveDelete} id='statut' onChange={(e)=> setPermissionUser({...permissionUser, haveDelete: e.target.checked})}/>
                                <label htmlFor='statut'>Supprimer</label>
                            </div> 
                        </div>
                    </div>

                    {/* Rapports */}
                    <div className="item_check_form_modal_role">
                        <div className="modal_menu_name">
                            <h6>Accès aux rapports</h6>
                        </div>
                        <div className='modal_menu_checkbox_group'>
                            <div className="modal_menu_checkbox_item">
                                <input type='checkbox' checked={permissionRapport.haveView} id='statut' onChange={(e)=> setPermissionRapport({...permissionRapport, haveView: e.target.checked})}/>
                                <label htmlFor='statut'>Lire</label>
                            </div>  

                            <div className="modal_menu_checkbox_item">
                                <input type='checkbox' checked={permissionRapport.haveAdd} id='statut' onChange={(e)=> setPermissionRapport({...permissionRapport, haveAdd: e.target.checked})}/>
                                <label htmlFor='statut'>Ajouter</label>
                            </div> 

                            <div className="modal_menu_checkbox_item">
                                <input type='checkbox' checked={permissionRapport.haveEdit} id='statut' onChange={(e)=> setPermissionRapport({...permissionRapport, haveEdit: e.target.checked})}/>
                                <label htmlFor='statut'>Modifier</label>
                            </div> 

                            <div className="modal_menu_checkbox_item">
                                <input type='checkbox' checked={permissionRapport.haveDelete} id='statut' onChange={(e)=> setPermissionRapport({...permissionRapport, haveDelete: e.target.checked})}/>
                                <label htmlFor='statut'>Supprimer</label>
                            </div> 
                        </div>
                    </div>

                </div>

                <div className='button_form_modal_role'>
                    <button onClick={()=> props.setIsOpen(false)}>Annuler</button>
                    <button onClick={(e)=>handleSave(e)}>Ajouter</button>
                </div>
            </form>
        </div>
    </div>
  )
}

export default AddRole