import dashboard from '../../assets/image/dashboard.png'
import article from '../../assets/image/products.png'
import addlist from '../../assets/image/addlist.png'
import list from '../../assets/image/list.png'
import pos from '../../assets/image/pos_terminal.png'
import accounting from '../../assets/image/accounting.png'
import profile_icon from '../../assets/image/profile.png'
import sale from '../../assets/image/sale.png'
import expense from '../../assets/image/low_price.png'
import contact_user from '../../assets/image/contacts_user.png'
import report from '../../assets/image/report_1.png'
import team from '../../assets/image/team.png'

export const menu = [
    {
        id : 1,
        title : 'Dashboard',
        icon : dashboard,
        path : '/',
    },
    {
        id : 2,
        title : 'Articles',
        icon : article,
        childrens : [
            {
                id : 20,
                title : 'Liste Articles',
                icon : list,
                path : '/articles'
            },
            {
                id : 21,
                title : 'Ajouter Article',
                icon : addlist,
                path : '/addArticle'
            },
            {
                id : 22,
                title : 'Liste Categories',
                icon : list,
                path : '/categories'
            }
        ]
    },
    {
        id : 3,
        title : 'Ventes',
        icon : sale,
        childrens : [
            {
                id : 30,
                title : 'Liste ventes',
                icon : list,
                path : '/orders'
            },
            {
                id : 31,
                title : 'Point de vente',
                icon : pos,
                path : '/pos'
            }
        ]
    },
    {
        id : 4,
        title : 'Dépenses',
        icon : expense,
        childrens : [
            {
                id : 40,
                title : 'Lister Dépenses',
                icon : list,
                path : '/expenses' 
            },
            {
                id : 41,
                title : 'Ajouter Dépenses',
                icon : addlist,
                path : '/addExpense' 
            }
        ]
    },
    {
        id : 5,
        title : 'Clients',
        icon : contact_user,
        childrens : [
            {
                id : 50,
                title : 'Lister Clients',
                icon : list,
                path : '/customers'
            },
            {
                id : 51,
                title : 'Ajouter Client',
                icon : addlist,
                path : '/addCustomer'
            }
        ]
    },
    {
        id : 6,
        title : 'Fournisseur',
        icon : contact_user,
        childrens : [
            {
                id : 60,
                title : 'Lister Fournisseurs',
                icon : list,
                path: '/suppliers'
            },
            {
                id : 61,
                title : 'Ajouter Fournisseur',
                icon : addlist,
                path : '/addSupplier'
            }
        ]
    },
    {
        id : 7,
        title : 'Gestion utilisateurs',
        icon : team,
        childrens : [
            {
                id : 70,
                title : 'Utilisateurs',
                icon : list,
                path : '/users'
            },
            {
                id : 71,
                title : 'Ajouter utilisateur',
                icon : addlist,
                path : '/addUser'
            },
            {
                id : 72,
                title : 'Lister roles',
                icon : list,
                path : '/roles'
            }
        ]
    },
    {
        id : 8,
        title : 'Rapport',
        icon : report,
        childrens : [
            {
                id : 82,
                title : 'Rapport Commandes',
                icon : accounting,
                path : '/OrderReports'
            },
            // {
            //     id : 83,
            //     title : "Journal d'activité",
            //     icon : accounting,
            //     path : '/reports'
            // }
        ]
    },
    {
        id : 9,
        title : 'Profile',
        icon : profile_icon,
        path : '/profile'
    }
]