import React, { useContext, useEffect, useState } from 'react'
import { ToastContext } from '../../utils/contexts/context';
import { useNavigate, useParams } from 'react-router-dom';
import { createSupplier, getSupplierById, updateSupplier } from '../../utils/services/supplierService';
import icon_ok from '../../assets/image/icon_ok.png'
import icon_cancel from '../../assets/image/icon_cancel.png'
import contact_user from '../../assets/image/contacts_user.png'

function AddSupplier() {

    const [toast, setToast] = useContext(ToastContext);

    const id_params = useParams();

    // Date vente 
    const today= new Date();
    const date=new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(today);

    const navigate = useNavigate();


    // Nombre aléatoire
    const randomNumberInRange = (min, max) =>{
        return Math.floor(Math.random()
                * (max - min + 1)) + min;
    }
    
    // Debut des states customer
    const [type,setType] = useState('particulier')
    const [code, setCode] = useState(`F-${randomNumberInRange(100, 10000)}`)
    const [nom, setNom] = useState('')
    const [prenom, setPrenom] = useState('')
    const [email, setEmail] = useState('')
    const [tel, setTel] = useState(0)
    const [adresse, setAdresse] = useState('')
    const [date_inscription, setData_Inscription] = useState(date);

    // Si on fait appel a ce composant pour une modification
    useEffect(()=>{
        getContact()
    },[])

    const getContact=()=>{
        if(id_params.id>0){
            getSupplierById(id_params.id)
            .then((resp)=>{
                setType(resp.data.type_fournisseur)
                setCode(resp.data.code)
                setNom(resp.data.nom)
                setPrenom(resp.data.prenom)
                setEmail(resp.data.email)
                setTel(resp.data.tel)
                setAdresse(resp.data.adresse)
                // setData_Inscription(resp.data.date_inscription)
            }).catch((error)=>{
                console.log(error)
            })
        }
        
    }

    const handleSave = (e) =>{
        e.preventDefault();
        let dataSupplier = {
            'type_fournisseur':type,
            'code':code,
            'nom':nom,
            'prenom':prenom,
            'email':email,
            'tel':tel,
            'adresse':adresse,
            // 'date_inscription':date_inscription
        }
        

        if(id_params.id>0){
            updateSupplier(id_params.id, dataSupplier)
            .then((resp)=>{
                if(resp.data.message){
                    setToast({toast, isOpen:true, isSuccess: true, message: resp.data.message, icon:icon_ok})
                    navigate('/suppliers')
                }else{
                    setToast({toast, isOpen:true, isSuccess: false, message: resp.data.errors[0].message, icon:icon_cancel})
                }
            }).catch((error)=>
                console.log(error)
            ) 
        }else{
            createSupplier(dataSupplier)
            .then((resp)=>{
                if(resp.data.message){
                    setToast({toast, isOpen:true, isSuccess: true, message: resp.data.message, icon:icon_ok})
                    navigate('/suppliers')
                }else{
                    setToast({toast, isOpen:true, isSuccess: false, message: resp.data.errors[0].message, icon:icon_cancel})
                }
            }).catch((error)=>
                console.log(error)
            )
        }
           
    }

  return (
    <div className='contact_container'>
        <div className="header_container">
            <div className="div">
            <img src={contact_user} alt='icon'/>
            <h4>{id_params.id>0 ? "Mise à Jour" : 'Nouvel Fournisseur'}</h4>
            </div>
            
            <h6>{id_params.id>0 ? "Modifier les propriétés du Fournisseur" : "Ajouter de nouvel Fournisseur"}</h6>
        </div>

        <div className="body_form">

            <form className="form_container" onSubmit={handleSave}>

                <div className="inputs_form_container">
                        <div className="input_form">
                            <label>Code de Reference</label>
                            <input type='text' value={code} placeholder='Code client' onChange={(e)=> setCode(e.target.value)} disabled/>
                        </div>
                        <div className="input_form">
                            <label>Type de Client</label>
                            <select value={type} onChange={(e)=> setType(e.target.value)} required>
                                <option>Choisir le type de fournisseur</option>
                                <option value="particulier">Particulier</option>
                                <option value="entreprise">Entreprise</option>
                            </select>
                        </div>

                        <div className="input_form">
                            <label>Nom Fournisseur</label>
                            <input type='text' value={nom} onChange={(e)=> setNom(e.target.value)} placeholder="Saisie le nom du client" required/>
                        </div>

                        <div className="input_form">
                            <label>Prenom Fournisseur</label>
                            <input type='text' value={prenom} onChange={(e)=> setPrenom(e.target.value)} placeholder="Saisie le prenom du client" required/>
                        </div>

                        <div className="input_form">
                            <label>Email</label>
                            <input type='text' placeholder='contact@gmail.com' value={email} onChange={(e)=> setEmail(e.target.value)} required/>
                        </div>

                        <div className="input_form">
                            <label>Telephone</label>
                            <input type='number' placeholder='Son contact' value={tel} onChange={(e)=> setTel(e.target.value)} required/>
                        </div>

                        <div className="input_form">
                            <label>Adresse</label>
                            <input type='text' placeholder='Son adresse' value={adresse} onChange={(e)=> setAdresse(e.target.value)} required/>
                        </div>

                        <div className="input_form">
                            <label>Date d'inscription</label>
                            <input type='text' value={date_inscription} onChange={(e)=> setData_Inscription(e.target.value)} disabled/>
                        </div>
                </div>

                <div className="button_valide_form">
                    <button onClick={()=> navigate('/suppliers')}>Annuler</button>
                    <button>Sauvegarder</button>
                </div>

            </form>

        </div>
    </div>
  )
}

export default AddSupplier