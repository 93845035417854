import React, { useContext, useEffect, useState } from 'react'
import { ToastContext } from '../../utils/contexts/context';
import { useNavigate, useParams } from 'react-router-dom';
import { createUser, getUserById, updateUser } from '../../utils/services/UserService';
import icon_ok from '../../assets/image/icon_ok.png'
import icon_cancel from '../../assets/image/icon_cancel.png'
import team_icon from '../../assets/image/team.png'
import { getAllRoles } from '../../utils/services/roleService';
import { getItem } from '../../utils/services/localStorage';
import AddRole from '../../components/addRole/addRole';

function AddUser() {

    // ***********  Pour gerer les authorizations  ***********
  const [privileges, setPrivileges] = useState([])

  const getUserConnecter = () =>{
    getUserById(getItem('userId'))
    .then((resp)=>{
      setPrivileges(resp.data.role.privileges)
    }).catch((error)=>{
      console.log(error)
    })
  }

  let onePrivilege ={}

  for(let privilege of privileges){
    if(privilege.menu === "Gestion Articles"){
      onePrivilege = { 
                  'haveView' :privilege.haveView,
                  'haveAdd': privilege.haveAdd,
                  'haveEdit':privilege.haveEdit,
                  'haveDelete':privilege.haveDelete
                }
    }
  }

  useEffect(()=>{
    getUserConnecter();
  },[])
// *****************************

    const [toast, setToast] = useContext(ToastContext);

    const id_params = useParams();

    // Date vente 
    const today= new Date();
    const date=new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(today);

    const navigate = useNavigate();

    // Pour recuperer la liste des roles de la base de données pour le input select
    const [roles, setRoles] = useState([])
    const getRoles = () =>{
        getAllRoles()
        .then((resp)=> {
            setRoles(resp.data)
          })
          .catch((error)=>{
              console.log(error)
          })
    }

    const [modalRole, setModalRole] = useState(false);

    // Debut des states customer
    const [nom, setNom] = useState('')
    const [prenom, setPrenom] = useState('')
    const [email, setEmail] = useState('')
    const [tel, setTel] = useState(0)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [role, setRole] = useState()
    const [statut, setStatut] = useState(true)
    const [date_inscription, setDate_Inscription] = useState(date);
    const [image, setImage] = useState({})

    // Si on fait appel a ce composant pour une modification
    useEffect(()=>{
        getUser()
        if(onePrivilege.haveAdd===false){
            navigate('/')
        }
    },[])
    
    useEffect(()=>{
        getRoles()
    }, [modalRole])

    const getUser=()=>{
        if(id_params.id>0){
            getUserById(id_params.id)
            .then((resp)=>{
                setNom(resp.data.nom)
                setPrenom(resp.data.prenom)
                setEmail(resp.data.email)
                setTel(resp.data.tel)
                setUsername(resp.data.username)
                setPassword(resp.data.password)
                setRole(resp.data.roleId)
                setStatut(resp.data.statut)
                setImage(resp.data.imageUrl)
            }).catch((error)=>{
                console.log(error)
            })
        }
        
    }

    const handleSave = (e) =>{
        e.preventDefault();
        let dataUser = new FormData()
        dataUser.append('nom',nom);
        dataUser.append('prenom',prenom);
        dataUser.append('email',email);
        dataUser.append('tel',tel);
        dataUser.append('username',username);
        dataUser.append('password',password);
        dataUser.append('roleId',role);
        dataUser.append('statut',statut);
        dataUser.append('date_inscription',date_inscription);
        dataUser.append('imageUrl',image);
        if(id_params.id>0){
            updateUser(id_params.id, dataUser)
            .then((resp)=>{
                if(resp.data.message){
                    setToast({toast, isOpen:true, isSuccess: true, message: resp.data.message, icon:icon_ok})
                    navigate('/users')
                }else{
                    setToast({toast, isOpen:true, isSuccess: false, message: resp.data.errors[0].message, icon:icon_cancel})
                }
            }).catch((error)=>
                console.log(error)
            ) 
        }else{
            createUser(dataUser)
            .then((resp)=>{
                if(resp.data.message){
                    setToast({toast, isOpen:true, isSuccess: true, message: resp.data.message, icon:icon_ok})
                    navigate('/users')
                }else{
                    setToast({toast, isOpen:true, isSuccess: false, message: resp.data.errors[0].message, icon:icon_cancel})
                }
            }).catch((error)=>
                console.log(error)
            )
        }
           
    }

    // Methode pour upload l'image
    const handleImage=(e)=>{
        setImage(e.target.files[0]);
    }

    const handleAddRole = () =>{
        setModalRole(true)
    }

    useEffect(()=>{
        if(onePrivilege.haveAdd===false){
            navigate('/')
        }
    },[privileges])
    
  return onePrivilege.haveAdd ? (
    <div className='contact_container'>
        <div className="header_container">
            <div className="div">
            <img src={team_icon} alt='icon'/>
            <h4>{id_params.id>0 ? "Mise à Jour" : 'Nouvel utilisateur'}</h4>
            </div>
            
            <h6>{id_params.id>0 ? "Modifier les propriétés d'utilisateur" : "Ajouter de nouvel Utilisateur"}</h6>
        </div>

        <div className="body_form">

            <form className="form_container" onSubmit={handleSave}>

                <div className="inputs_form_container">

                        <div className="input_form">
                            <label>Nom</label>
                            <input type='text' value={nom} onChange={(e)=> setNom(e.target.value)} placeholder="Saisie le nom" required/>
                        </div>

                        <div className="input_form">
                            <label>Prenom</label>
                            <input type='text' value={prenom} onChange={(e)=> setPrenom(e.target.value)} placeholder="Saisie le prenom" required/>
                        </div>

                        <div className="input_form">
                            <label>Email</label>
                            <input type='text' placeholder='contact@gmail.com' value={email} onChange={(e)=> setEmail(e.target.value)} required/>
                        </div>

                        <div className="input_form">
                            <label>Username</label>
                            <input type='text' placeholder='@user' value={username} onChange={(e)=> setUsername(e.target.value)} required/>
                        </div>

                        <div className="input_form_more">
                            <label>Role</label>
                            <div className="input_form_more_group">
                                <select value={role} onChange={(e)=> setRole(e.target.value)} required>
                                    <option>Choisir le role de l'utilisateur</option>
                                    {roles.map((item, index)=>(
                                        <option key={index} value={item.id}>{item.nom}</option>
                                    ))}
                                </select>
                                <div className="input_form_more_button" onClick={()=>handleAddRole()}>
                                    +
                                </div>
                            </div>
                        </div>

                        <div className="input_form">
                            <label>Telephone</label>
                            <input type='number' placeholder='Son contact' value={tel} onChange={(e)=> setTel(e.target.value)}/>
                        </div>


                        <div className="input_form">
                            <label>Mot de passe</label>
                            <input type='password' placeholder='Son mot de passe' value={password} onChange={(e)=> setPassword(e.target.value)} required/>
                        </div>

                        <div className="input_form">
                            <label>Date d'inscription</label>
                            <input type='text' value={date_inscription} onChange={(e)=> setDate_Inscription(e.target.value)} disabled/>
                        </div>

                        <div className='input_form_checkbox_choice'>
                            <div>
                                <input type='checkbox' checked={statut} id='statut' onChange={(e)=> setStatut(e.target.checked)}/>
                                <label htmlFor='statut'>Compte Actif</label>
                            </div>
                            <h6>Permettre de rendre active ce compte</h6>    
                        </div>

                        <div className="input_form">
                            <label htmlFor='files'>Upload image</label>
                            <input id='files' type='file' onChange={handleImage} accept=".png, .jpg, .jpeg"/>
                        </div>
                </div>

                <div className="button_valide_form">
                    <button onClick={()=> navigate('/users')}>Annuler</button>
                    <button>Sauvegarder</button>
                </div>

            </form>

        </div>
        {modalRole && <AddRole setIsOpen={setModalRole}/>}
    </div>
  ) : (
    <></>
  )
}

export default AddUser