import axios from 'axios';
import { getItem } from './localStorage';
import {env} from '../environments/environment'

const urlApi = env;

const token = getItem('authtoken')

export function getAllRoles(){
    return axios.get(`${urlApi}/roles/all`, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    })
}

export function createRole(role){
    return axios.post(`${urlApi}/roles/create`, role, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    })
}

export function getRoleById(id){
    return axios.get(`${urlApi}/roles/` + id, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    });
}

export function updateRole(id, role){
    return axios.put(`${urlApi}/roles/` +id, role, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    });
}

export function deleteRole(id){
    return axios.delete(`${urlApi}/roles/remove/` + id, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    });
}