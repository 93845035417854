import React, { useContext, useEffect, useRef, useState } from 'react'
import { ToastContext } from '../../utils/contexts/context';
import { useReactToPrint } from 'react-to-print';
import { getItem } from '../../utils/services/localStorage';
import { getOrderById, updateOrder } from '../../utils/services/orderService';
import printer_icon from '../../assets/image/icon_print.png';
import icon_ok from '../../assets/image/icon_ok.png'
import icon_cancel from '../../assets/image/icon_cancel.png'
import { ComponentToPrint } from '../printer/printer';
import Modal from '../modal/modal';

function UpdateOrder(props) {

    const [toast, setToast] = useContext(ToastContext);

    const [noFacture, setNoFacture] = useState('');
    const [nomClient, setNomClient] = useState('');
    const [statutPayment, setStatutPayment]=useState("");
    const [methodPayment, setMethodPayment]= useState("");
    const [items, setItems] = useState(0);
    const [montantVendu, setMontantVendu] = useState(0);
    const [montantVerse, setMontantVerse] = useState(0);
    const [montantDejaVerse, setMontantDejaVerse] = useState(0);
    const [montantDue, setMontantDue] = useState(0)
    const [discount, setDiscount] = useState(0);
    const [oldDiscount, setOldDiscount] = useState(0);
    const [oldMonnaie, setOldMonnaie] =useState(0);
    const [note, setNote] = useState("")
    const [dateVente, setDateVente] = useState()
    const [vendeur, setVendeur] = useState('')
    const [articles, setArticles] = useState([])

    // Printer
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    // Dialog
    const [dialog, setDialog] = useState({
        icon:'',
        title:'',
        message: '',
        isOpen: false
    }) 
    
    // Ce qu'on doit faire selon le choix de la Boite de dialog
    const confirmeDiolog = (choose) =>{
        if(choose){
            handlePrint()
            props.setIsOpen(false)
            setDialog('', '', false)
        }else{
            setDialog('', '', false)
            props.setIsOpen(false)
        }
    }

    const handleValide = (e) =>{
        e.preventDefault();
        
        const due = (montantDue-montantVerse-discount)>=0 ? montantDue - montantVerse - discount : 0;
        
        const montantVerseTotal = parseFloat(montantDejaVerse) + parseFloat(montantVerse);

        const newMonnaie = (montantVerse-(props.totalVente-discount));
        const monnaie = newMonnaie >=0 ? (oldMonnaie + newMonnaie) : 0;

        let order = {
            'nomClient':nomClient,
            'statutPayment': due === 0 ? "au comptant" : (due>0 ? "balance" :statutPayment),
            'modePayment': methodPayment,
            'montantVerser': montantVerseTotal,
            'montantDue': due,
            'monnaie': monnaie,
            'discount': parseFloat(discount) + parseFloat(oldDiscount),
            'noteVente':note,
        }

        updateOrder(props.id, order)
        .then((resp)=>{
            if(resp.data.message){
                setToast({toast, isOpen:true, isSuccess: true, message: resp.data.message, icon:icon_ok})
                
                setDialog({
                    icon:printer_icon,
                    title:'Impression',
                    message: 'Voulez-vous imprimer votre fiche?',
                    isOpen:true,
                })
            }else{
                setToast({toast, isOpen:true, isSuccess: false, message: "Enregistrement echoué", icon:icon_cancel})
            }
        }).catch((error)=>{
            console.log(error)
        })
    }

    const getOrder = () =>{
        getOrderById(props.id)
        .then((resp)=>{
            setNoFacture(resp.data.noFacture)
            setNomClient(resp.data.nomClient)
            setStatutPayment(resp.data.statutPayment)
            setMethodPayment(resp.data.modePayment)
            setItems(resp.data.items)
            setMontantVendu(resp.data.montantVendu)
            setMontantDejaVerse(resp.data.montantVerser)
            setMontantDue(resp.data.montantDue)
            setOldDiscount(resp.data.discount)
            setOldMonnaie(resp.data.monnaie)
            setNote(resp.data.note)
            setDateVente(resp.data.dateVente)
            setVendeur(resp.data.user.username)
            setArticles(resp.data.articles)
        }).catch((error)=>{
            console.log(error)
        })
    }

    useEffect(()=>{
        getOrder()
    },[])

  return (
    <div className='payment_container'>
        <div className="payment_modal">
            {/* Header */}
            <div className="payment_modal_header">
                <div>
                    <h4>Remboursement</h4>
                </div>
                <div className='payment_modal_header_close' onClick={()=> props.setIsOpen(false)}>
                    x
                </div>
            </div>

            {/* Detail */}
            <div className='payment_modal_detail'>
                <h5>Details client</h5>
                
                <div className='payment_modal_detail_customer'>
                        {/* Group left payemnt detail */}
                        <div className="group_payment_modal_customer">
                            <div className="payment_modal_code_customer">
                                <h6>REF</h6>
                            </div>
                            <div className="payment_modal_about_customer">
                                <div className="payment_modal_about_customer_name">
                                    <h6>{nomClient}</h6>
                                </div>
                                <div className="payment_modal_about_customer_orders">
                                    <h6>No Commande : {noFacture}</h6>
                                </div>
                            </div>
                        </div>
                        {/* Group Right payment detail */}
                        <div className="group_payment_modal_about_customer_date">
                            <h6>{dateVente}</h6>
                        </div>
                </div>

                <div className="payment_modal_detail_order">
                    <h5>Details Transaction</h5>
                    
                    <div className='payment_modal_detail_order_container'>
                        {articles.map((item, index)=>(
                            <div key={index} className='payment_modal_detail_order_item'>
                                <div className="payment_modal_detail_order_item_detail">
                                    <h6>{item.nomArticle} / {item.variante}</h6>
                                    <h6>Prix: {item.prixVente} $HT</h6>
                                </div>
                                <div className="payment_modal_detail_order_item_amount">
                                    <h6>{item.orderArticle.quantity}x</h6>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className='payment_modal_detail_order_montant'>
                        <h6>Item</h6>
                        <h6>{items}x</h6>
                    </div>

                    <div className='payment_modal_detail_order_montant'>
                        <h6>Balance</h6>
                        <h6>{(montantDue - montantVerse - discount)>0 ? (montantDue - montantVerse - discount) : 0} $HT</h6>
                    </div>

                    <div className='payment_modal_detail_order_montant'>
                        <h6>Montant Versé</h6>
                        <h6>{montantVerse} $HT</h6>
                    </div>

                    <div className='payment_modal_detail_order_montant'>
                        <h6>Rendu de monnaie</h6>
                        <h6>{(montantVerse-(montantDue-discount))>0 ? (montantVerse-(montantDue-discount)) : 0} $HT</h6>
                    </div>
                    
                    <div className='payment_modal_detail_order_montant'>
                        <h5>Dette</h5>
                        <h6> {montantDue} $HT</h6>
                    </div>
                </div>

            </div>

            {/* Transaction */}
            <div className='payment_modal_transaction'>
                <h5>Complétez votre transaction</h5>
                
                <form className='payment_form_group' onSubmit={handleValide}>

                    <div className="input_form_payment">
                        <label>Nom Client</label>
                        <input type='text' placeholder='le nom du client' value={nomClient} onChange={(e)=> setNomClient(e.target.value)} required/>
                    </div>

                    <div className="input_form_payment">
                        <label>Methode Paiement</label>
                        <select value={methodPayment} onChange={(e)=> setMethodPayment(e.target.value)} required>
                            <option></option>
                            <option value="cash">Cash</option>
                            <option value="virement">Virement telephonique</option>
                            <option value="credit card">Carte</option>
                        </select>
                    </div>

                    <div className="input_form_payment">
                        <label>Statut Paiement</label>
                        <select value={statutPayment} onChange={(e)=> setStatutPayment(e.target.value)} disabled>
                            <option></option>
                            <option value="au comptant">Au comptant</option>
                            <option value="a credit">A credit</option>
                            <option value="balance">Balance</option>
                        </select>
                    </div>

                    <div>
                        <div className="input_form_payment">
                            <label>Montant Versé</label>
                            <input type='number' value={montantVerse} placeholder='0.0 $HT' onChange={(e)=> setMontantVerse(e.target.value)}/>
                        </div>
                        <div className="input_form_payment">
                            <label>Discount</label>
                            <input type='number' value={discount} placeholder='0.0 $HT' onChange={(e)=> setDiscount(e.target.value)}/>
                        </div>
                    </div>
                    {/* {statutPayment !== "a credit" && 
                    } */}

                    <div className="input_form_payment">
                        <label>Note de vente</label>
                        <textarea placeholder='Information sur la vente' onChange={(e)=> setNote(e.target.value)}/>
                    </div> 

                    <button className='input_form_payment_button'>Valider Paiement</button>
                </form>
                
            </div>
        </div>

        {/* Pour le composant à printer */}
        <div className="printer" >
            <ComponentToPrint ref={componentRef} 
                cart={articles} totalVente={montantVendu} 
                totalAmount={items} customer={nomClient} date={dateVente} 
                codeVente={noFacture} salesPerson={vendeur} 
                montantVerser={montantVerse} monnaie={montantVerse > (montantDue-discount) ? montantVerse-(montantDue-discount) : 0}
                discount={discount}
                balance={(montantDue-montantVerse-discount)>=0 ? montantDue - montantVerse - discount : 0} 
                inView={true} />
        </div>
        
        <Modal onDialog={confirmeDiolog} dialog={dialog} setDialog={setDialog}/>
    </div>
  )
}

export default UpdateOrder