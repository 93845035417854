import React, { useContext, useEffect, useState } from 'react'
import { ToastContext } from '../../utils/contexts/context';
import { deleteOrder, getAllOrders } from '../../utils/services/orderService';
import { useNavigate } from 'react-router-dom';
import deleteIcon from '../../assets/image/delete_1.png'
import eye from '../../assets/image/eye.png'
import excelIcon from '../../assets/image/excel.png'
import refreshIcon from '../../assets/image/refresh.png'
import * as XLSX from 'xlsx';
import icon_ok from '../../assets/image/icon_ok.png'
import icon_cancel from '../../assets/image/icon_cancel.png'
import icon_sale from '../../assets/image/sale.png'
import { getUserById } from '../../utils/services/UserService';
import { getItem } from '../../utils/services/localStorage';
import pos from '../../assets/image/pos_terminal.png'
import article_icon from '../../assets/image/trolley.png'
import dette_icon from '../../assets/image/dette.png'
import money_icon from '../../assets/image/money_bag.png'
import search_icon from '../../assets/image/search.png'
import dateFormat from 'dateformat';

function RapportOrder() {
    // ***********  Pour gerer les authorizations  ***********
  const [privileges, setPrivileges] = useState([])

  const getUser = () =>{
    getUserById(getItem('userId'))
    .then((resp)=>{
      setPrivileges(resp.data.role.privileges)
    }).catch((error)=>{
      console.log(error)
    })
  }

  let onePrivilege ={}

  for(let privilege of privileges){
    if(privilege.menu === "Rapports"){
      onePrivilege = { 
                  'haveView' :privilege.haveView,
                  'haveAdd': privilege.haveAdd,
                  'haveEdit':privilege.haveEdit,
                  'haveDelete':privilege.haveDelete
                }
    }
  }


  const [keyWork, setKeyWork] = useState('');
  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  const [toast, setToast] = useContext(ToastContext);

  const column = ["ID", "No Facture","client", "Statut du paiement", "Méthode de paiement", "Total éléments", "Montant vendu", "Montant versé", "Montant Dû", "Discount", "statut expédition", "Vendu par", "Date vente", "Action"];
  const [data, setData] = useState([])

  useEffect(()=>{
    getUser();
    handleGetOrders();
  },[])

  const handleGetOrders =()=>{
      getAllOrders()
          .then((resp)=> {
            setData(resp.data)
          })
          .catch((error)=>{
              console.log(error)
          })
  }

  const handleDelete = (id) =>{
    if(onePrivilege.haveDelete === true){
      deleteOrder(id)
      .then((resp)=>{
          if(resp.data.message){
            handleGetOrders()
            setToast({toast, isOpen:true, isSuccess: true, message: resp.data.message, icon:icon_ok})
          }else{
            setToast({toast, isOpen:true, isSuccess: false, message: "Suppression echouée", icon:icon_cancel})
          }
      }).catch((error)=>{
          console.log(error)
      })
    }else{
      setToast({toast, isOpen:true, isSuccess: false, message: "Vous n'avez pas accès ici", icon:icon_cancel})
    }
  }

  const handleView=(id)=>{
    if(onePrivilege.haveView === true){
      navigate(`/viewOrder/${id}`)
    }else{
      setToast({toast, isOpen:true, isSuccess: false, message: "Vous n'avez pas accès ici", icon:icon_cancel})
    }
  }

  const handleSearch = (e) =>{
    e.preventDefault()
    setSearch(keyWork)
  }

  const refreshData = () =>{
    window.location.reload();
  }

// Export to Excel
  const handleOnExcel=()=>{
    var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.json_to_sheet(data);

    XLSX.utils.book_append_sheet(wb,ws, "MySheet1");
    XLSX.writeFile(wb, 'Rapport_orders.xlsx');
  }

  const [currentPage, setCurrentPage]= useState(1)
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = data.slice(firstIndex, lastIndex)
  const npage = Math.ceil(data.length/recordsPerPage)
  const numbers = [...Array(npage + 1).keys()].slice(1)

  const today= new Date();
  const [dateDebut, setDateDebut] = useState(dateFormat(new Date(today.getFullYear(), today.getMonth(), 1), "isoDate"));
  const [dateFin, setDateFin] = useState(dateFormat(today, "isoDate"));

  let totalEnCaisse = 0;
  let totalDette = 0;
  let totalItems = 0;
  let nbreOrder = 0;
  let monnaie = 0;
  for (let i of data) {
      if(dateFormat(i.dateVente, "isoDate")>=dateDebut && dateFormat(i.dateVente, "isoDate")<=dateFin){
        totalEnCaisse =totalEnCaisse + (i.montantVerser) ;
        totalDette =totalDette + (i.montantDue) ;
        totalItems =totalItems + (i.items) ;
        monnaie = monnaie + (i.monnaie)
        nbreOrder +=1;
      }
  }


  useEffect(()=>{
    if(onePrivilege.haveView===false){
      navigate('/')
    }
  },[privileges])

  const [filtrage, setFiltrage]= useState('')

  return onePrivilege.haveView ? (
    <div className='order_container'>

      <div className="header_container">
        <div className="div">
          <img src={icon_sale} alt='icon'/>
          <h4>Rapport Commandes</h4>
        </div>
        
        <h6>La liste de toutes les commandes</h6>
      </div>

      <div className="apercu_container">
          <div className="card_apercu_container">
              <div className="card_apercu_container_left">
                  <h3>{nbreOrder}</h3>
                  <h4>Commandes</h4>
              </div>
              <div className="card_apercu_container_right">
                  <div className="icon_card" style={{backgroundColor: '#6ad1df'}}>
                      <img src={pos} alt=''/>
                  </div>
              </div>
          </div>

          <div className="separator"></div>

          <div className="card_apercu_container">
              <div className="card_apercu_container_left">
                  <h3>{totalEnCaisse-monnaie} $HT</h3>
                  <h4>Montant en Caisse</h4>
              </div>
              <div className="card_apercu_container_right">
                  <div className="icon_card" style={{backgroundColor: '#6ad1df'}}>
                      <img src={money_icon} alt=''/>
                  </div>
              </div>
          </div>

          <div className="separator"></div>

          <div className="card_apercu_container">
              <div className="card_apercu_container_left">
                  <h3>{totalDette} $HT</h3>
                  <h4>Toutes les dettes</h4>
              </div>
              <div className="card_apercu_container_right">
                  <div className="icon_card" style={{backgroundColor: '#6ad1df'}}>
                      <img src={dette_icon} alt=''/>
                  </div>
              </div>
          </div>

          <div className="separator"></div>

          <div className="card_apercu_container">
              <div className="card_apercu_container_left">
                  <h3>{totalItems}</h3>
                  <h4>Tous les articles vendus</h4>
              </div>
              <div className="card_apercu_container_right">
                  <div className="icon_card" style={{backgroundColor: '#6ad1df'}}>
                      <img src={article_icon} alt=''/>
                  </div>
              </div>
          </div>
      </div>

      <div className="list_categories">

        <div className="header_list">

            <div className="header_list_body">

                <div className="header_body_left">
                  <button><img src={refreshIcon} alt='refresh' onClick={()=> refreshData()}/></button>
                  <button><img src={excelIcon} alt='excel' onClick={()=> handleOnExcel()}/></button>
                  
                </div>
                <div className="header_body_right">
                  <div className="container_filter">
                    {/* Pour filter vos commandes */}
                    <form style={{display:'flex', flexDirection:'row', gap: '6px'}}>
                      <div className="input_form">
                          <label>Du:</label>
                          <input type='date' value={dateDebut} onChange={(e)=> setDateDebut(e.target.value)} placeholder='Titre'/>
                      </div>
                      <div className="input_form">
                          <label>Au:</label>
                          <input type='date' value={dateFin} onChange={(e)=> setDateFin(e.target.value)} placeholder='Titre'/>
                      </div>
                    </form>

                    <form>
                        <select value={filtrage} onChange={(e)=> setFiltrage(e.target.value)} >
                          <option value=''>Toutes les ventes</option>
                          <option value='au comptant'>Au comptant</option>
                          <option value='a credit'>A credit</option>
                          <option value='balance'>Balance</option>
                        </select>
                    </form>
                  </div>
                </div>

            </div>


            <div className="header_list_body">
                <div className="header_body_left">
                    <h6>Montrer</h6>
                    <form>
                        <select name='recordsPerPage' onChange={(e)=> setRecordsPerPage(e.target.value)}>
                          <option value='50'>50</option>
                          <option value='100'>100</option>
                          <option value='150'>150</option>
                          <option value='200'>200</option>
                          <option value='250'>250</option>
                        </select>
                    </form>
                    <h6>Entrées</h6>
                </div>

                <div className="header_body_right">
                    <form onSubmit={handleSearch}>
                          <input
                              value={keyWork}
                              onChange={(e)=>setKeyWork(e.target.value)}
                              placeholder='Recherche'
                          />
                          <button>
                            <img src={search_icon} alt='search'/>
                          </button>
                    </form>
                </div>

            </div>
            
        </div>
        {/* End header list */}

        <div className="content_table">
          <table>
            <thead>
              <tr>       
                  {column.map((item, index)=>(
                    <th key={index}>{item}</th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {records.map((item, index)=>
              (!filtrage || filtrage === item.statutPayment) &&
              (!search || search.toLowerCase() === item.noFacture.toLowerCase() 
              || search.toLowerCase() === item.nomClient.toLowerCase()
              || search.toLowerCase() === item.user.username.toLowerCase()) &&
              (dateFormat(item.dateVente, "isoDate")>=dateDebut && dateFormat(item.dateVente, "isoDate")<=dateFin)
              ?(
                <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.noFacture}</td>
                    <td>{item.nomClient}</td>
                    <td><h6 className={item.statutPayment === "a credit" ? "itemCredit" : (item.statutPayment === "balance" ? "itemBalance" : "itemComptant")}>{item.statutPayment}</h6></td>
                    <td>{item.statutPayment === "a credit" ? "Aucun" : item.modePayment}</td>
                    <td>{item.items}x</td>
                    <td>{item.montantVendu} $HT</td>
                    <td>{item.montantVerser-item.monnaie} $HT</td>
                    <td>{item.montantDue} $HT</td>
                    <td>{item.discount} $HT</td>
                    <td>{item.statutExpedition}</td>
                    <td>{item.user.username}</td>
                    <td>{dateFormat(item.dateVente, "isoDate")}</td>
                    <td style={{display:'flex', gap: '5px'}}>
                      {/* <img src={deleteIcon} alt='delete' className='delete_icon' onClick={()=>handleDelete(item.id)}/> */}
                      <img src={eye} alt='edit' className='update_icon' onClick={()=> handleView(item.id)}/>
                    </td>
                </tr>
              ) : (<p key={index}></p>))}
            </tbody>
          </table>
        </div>

        <div className="content_pagination">
              <ul className='pagination'>
                  <li className='page_item'>
                      <button onClick={prePage} className='page_link'>prev</button>
                  </li>
                  <div className="list_page">
                      {
                          numbers.map((n,i)=>(
                              <li key={i} className='page_item'>
                                  <button onClick={()=>changeCPage(n)} className={currentPage===n ? 'active' : 'page_link'}>{n}</button>
                              </li>
                          ))
                      }
                  </div>
                  <li className='page_item'>
                      <button onClick={nextPage} className='page_link'>next</button>
                  </li>
              </ul>
        </div>


      </div>
    </div>
  ) : (
    <></>
  )

  function prePage(){
    if(currentPage !== 1){
        setCurrentPage(currentPage - 1)
    }
  }
  function changeCPage(id){
    setCurrentPage(id)
  }

  function nextPage(){
    if(currentPage !== npage){
        setCurrentPage(currentPage + 1)
    }
  }
}

export default RapportOrder