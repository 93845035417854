import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext, ToastContext } from '../../utils/contexts/context'
import { createUser } from '../../utils/services/UserService'
import icon_ok from '../../assets/image/icon_ok.png'
import icon_cancel from '../../assets/image/icon_cancel.png'
import './register.css'

function Register() {
  const navigate = useNavigate()

  const [toast, setToast] = useContext(ToastContext);

  const [isAuthenticated, setIsAuthenticated] = useContext(AuthContext)

  const [credentials, setCredentials] = useState({
        nom: '',
        prenom:'',
        username: '',
        email:'',
        tel:'',
        password: ''
  })

  useEffect(()=>{
      if(isAuthenticated===true){
          navigate('/')
      }
  },[setIsAuthenticated])

  const handleSubmit= async event =>{
      event.preventDefault();
      createUser(credentials)
      .then((resp)=>{
        if(resp.data){
            setToast({toast, isOpen:true, isSuccess: true, message: "Compte crée avec succès", icon:icon_ok})
            navigate('/login')
        }else{
            setToast({toast, isOpen:true, isSuccess: false, message: "Echec d'enregistrement", icon:icon_cancel})
        }
      }).catch((error)=>{
        alert(error)
      })
  }

return !isAuthenticated ? (
  <div className="register_container">
      <div className="register_container_right">
          <div className="register_header">
              <h2>Ligou</h2>
              <h4>Créer un nouveau compte</h4>
              <h5>Veillez renseigner vos coordonnées</h5>
          </div>
          <form onSubmit={handleSubmit} className='form_container_register'>
              <div className='form_group_register'>
                  <label className='form_label'>Nom</label>
                  <input type="text" value={credentials.nom} onChange={(e)=> setCredentials({...credentials, nom: e.target.value})} className='form_input_register' placeholder='Saisie votre nom' required></input>
              </div>
              <div className='form_group_register'>
                  <label className='form_label'>Prenom</label>
                  <input type="text" value={credentials.prenom} onChange={(e)=> setCredentials({...credentials, prenom: e.target.value})} className='form_input_register' placeholder='Saisie votre prenom' required></input>
              </div>
              <div className='form_group_register'>
                  <label className='form_label'>Username</label>
                  <input type="text" value={credentials.username} onChange={(e)=> setCredentials({...credentials, username: e.target.value})} className='form_input_register' placeholder='@nom_utilisateur' required></input>
              </div>
              <div className='form_group_register'>
                  <label className='form_label'>email</label>
                  <input type="mail" value={credentials.email} onChange={(e)=> setCredentials({...credentials, email: e.target.value})} className='form_input_register' placeholder='votre mail' required></input>
              </div>
              <div className='form_group_register'>
                  <label className='form_label'>Telephone</label>
                  <input type="tel" value={credentials.tel} onChange={(e)=> setCredentials({...credentials, tel: e.target.value})} className='form_input_register' placeholder='votre numero de telephone'></input>
              </div>
              <div className='form_group_register'>
                  <label className='form_label'>Password</label>
                  <input type="password" value={credentials.password} onChange={(e)=> setCredentials({...credentials, password: e.target.value})} className='form_input_register' placeholder='Mot de passe'></input>
              </div>
              <div className='form_group_button_register'>
                  <button className='form_button_login'>Enregistrer</button>
              </div>    
          </form>

          <div>
              <h6>Si vous avez deja un compte, cliquez ici <span onClick={()=> navigate('/login')} style={{fontWeight: 'bold', color: '#1A8D5F', cursor:'pointer'}}>Connecter</span></h6>
          </div>
      </div>
      <div className="register_container_left">
          {/* <div className="about_login_container">
              <h4>"Ce systeme est entièrement developper par Teklik services informatiques pour
                  l'entreprise El Shadai Dieu Merci. Il vous permettra de faire la gestion de vos
                  stock, de vos ventes, et bien d'autres encore."</h4>
          </div> */}
      </div>
  </div>
  ) : (<></>)
}

export default Register