import axios from 'axios';
import { getItem } from './localStorage';
import {env} from '../environments/environment'

const urlApi = env;

const token = getItem('authtoken')

export function getAllUsers(){
    return axios.get(`${urlApi}/auth/users`, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    })
}

export function getUserById(id){
    return axios.get(`${urlApi}/auth/users/`+id, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    })
}

export function getProfil(id){
    return axios.get(`${urlApi}/auth/profil/`+id, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    })
}

export function createUser(user){
    return axios.post(`${urlApi}/auth/signup`, user, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    })
}

export function updateUser(id, team){
    return axios.put(`${urlApi}/auth/edit/` +id, team, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    });
}

export function deleteUser(id){
    return axios.delete(`${urlApi}/auth/remove/` + id, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    });
}