import React, { useContext, useEffect, useState } from 'react'
import Card from '../../components/card/card'
import './pos.css'
import { getAllArticles } from '../../utils/services/articleService';
import Cart from '../../components/cart/cart';
import { getUserById } from '../../utils/services/UserService';
import { getItem } from '../../utils/services/localStorage';
import { useNavigate } from 'react-router-dom';
import { ToastContext } from '../../utils/contexts/context';
import icon_cancel from '../../assets/image/icon_cancel.png';
import defaultImage from '../../assets/image/default.png'
import InputModal from '../../components/inputModal/inputModal';

function Pos() {

  const [toast, setToast] = useContext(ToastContext);

  const navigate = useNavigate();

  // ***********  Pour gerer les authorizations  ***********
  const [privileges, setPrivileges] = useState([])

  const getUser = () =>{
    getUserById(getItem('userId'))
    .then((resp)=>{
      setPrivileges(resp.data.role.privileges)
    }).catch((error)=>{
      console.log(error)
    })
  }

  let onePrivilege ={}

  for(let privilege of privileges){
    if(privilege.menu === "pos"){
      onePrivilege = { 
                  'haveView' :privilege.haveView,
                  'haveAdd': privilege.haveAdd,
                  'haveEdit':privilege.haveEdit,
                  'haveDelete':privilege.haveDelete
                }
    }
  }
  
  // Sauvegarde des produits ajouté au cart dans le local storage
  const savedCart= localStorage.getItem('cart')
  const [cart, updateCart] = useState(savedCart ? JSON.parse(savedCart) : [])
  useEffect(()=>{
      localStorage.setItem('cart',JSON.stringify(cart))
  },
  [cart])

  const [articles, setArticles] = useState([])

  // Pour recuperer la categorie choisit 
  const [activeCategory, setActiveCategory] = useState('');
  const categoriesDoublons = articles.reduce((acc, item)=> 
        acc.includes(item.category) ? acc : acc.concat(item.category),
    [])
  // Ceci permet de filter le tableau de categories pour elimier les doublons
  const categoriesTrie = []
  const categories = categoriesDoublons.filter((item)=>{
    if(!categoriesTrie.includes(item.nomCategorie)){
      categoriesTrie.push(item.nomCategorie)
      return true
    }
    return false
  })

  useEffect(()=>{
    getUser()
  },[])

  useEffect(()=>{
    getArticles()
  },[cart])

  const getArticles=() =>{
    getAllArticles()
    .then((resp)=>{
      setArticles(resp.data)
    }).catch((error)=>{
      alert(error)
    })
  }

  const [added, setAdded] = useState({
    isOpen: false,
    id: 0,
    nom:'',
    categorie: '',
    description: '',
    image: '',
    variante: '',
    prixVente: 0,
    IsFollow: false,
    inStock: 1,
    stockFaible: ''
  })

  function addToCart(articleId, nom, description, image, variante, prixVente, prixDetail, suiviStock, enStock, stockDetail, itemsPerSet, stockFaible){
    if(onePrivilege.haveAdd === true){

      if(enStock <= stockFaible && suiviStock){
        setToast({toast, isOpen:true, isSuccess: false, message: " Bientôt Rupture de stock! ", icon:icon_cancel})
      }
      
      // Pour afficher le modal input
      setAdded({
        ...added, 
        isOpen: true, 
        id: articleId,
        nom:nom,
        description: description,
        image: image,
        variante: variante,
        prixVente: prixVente,
        prixDetail:prixDetail,
        IsFollow: suiviStock,
        inStock: enStock,
        stockDetail: stockDetail,
        itemsPerSet: itemsPerSet,
        stockFaible: stockFaible
      })

    }else{
      setToast({toast, isOpen:true, isSuccess: false, message: "Vous n'avez pas accès ici", icon:icon_cancel})
    }
    
  }

  useEffect(()=>{
    if(onePrivilege.haveView===false){
      navigate('/')
    }
  },[privileges])
  

  return onePrivilege.haveView ? (
    <div className="pos_container">
      <div className="pos_container_categories">
          <div className={activeCategory==='' ? 'card_categorie_active' : 'card_categorie'} onClick={()=>setActiveCategory('')}>
              <h6 className='card_categorie_name'>All Menu</h6>
          </div>
        {categories.map((item, index)=>(
          <div key={index} className={activeCategory === item.nomCategorie ? 'card_categorie_active': 'card_categorie'} onClick={()=>setActiveCategory(item.nomCategorie)}>
            <img src={item.imageUrl === "[object Object]" ? defaultImage : item.imageUrl} alt='cover' className={activeCategory === item.nomCategorie ?'card_categorie_cover_active':'card_categorie_cover'}/>
            <h6 className='card_categorie_name'>{item.nomCategorie}</h6>
          </div>
        ))}
      </div>


      <div className="pos_container_cards">
        {articles.map((item, index)=>
        item.enVente && 
        (!activeCategory || activeCategory === item.category.nomCategorie)
        ?(
          <div key={index} className='card_item_article' onClick={()=> addToCart(item.id, item.nomArticle,item.description,item.imageUrl, item.variante, item.prixVente, item.prixDetail, item.suiviStock, item.enStock, item.stockDetail,item.itemsPerSet, item.stockFaible)}>
            <Card imageUrl={item.imageUrl} title={item.nomArticle} categorie={item.category.nomCategorie} describe={item.description} variante={item.variante} price={item.prixVente} retailPrice={item.prixDetail} suiviStock={item.suiviStock} enStock={item.enStock} stockDetail={item.stockDetail} itemsPerSet={item.itemsPerSet} stockFaible={item.stockFaible}/>
          </div>
        ):null
        )}
      </div>
      <div className="pos_container_cart">
        <Cart cart={cart} updateCart={updateCart}/>
      </div>

      {added.isOpen && <InputModal setModal={setAdded} modal={added} cart={cart} updateCart={updateCart}/>}

    </div>
  ) : (
    <></>
  )
}

export default Pos