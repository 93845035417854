import React, { useContext, useEffect, useRef, useState } from 'react'
import './payment.css'
import { getAllCustomers, getCustomerById } from '../../utils/services/customerService'
import { createOrder } from '../../utils/services/orderService';
import { ToastContext } from '../../utils/contexts/context';
import icon_ok from '../../assets/image/icon_ok.png'
import icon_cancel from '../../assets/image/icon_cancel.png'
import { ComponentToPrint } from '../printer/printer';
import Modal from '../modal/modal';
import { useReactToPrint } from 'react-to-print';
import printer_icon from '../../assets/image/icon_print.png';
import { getItem } from '../../utils/services/localStorage';

function Payment(props) {

    const [toast, setToast] = useContext(ToastContext);

    const [customerId, setCustomerId] = useState(0);

    const [customers, setCustomers]= useState([]);
    const getCustomers =()=>{
        getAllCustomers()
        .then((resp)=>(
            setCustomers(resp.data)
        )).catch((error)=>{
            alert(error)
        })
    }

    const [customerNom, setCustomerNom] = useState("");
    const [customerCode, setCustomerCode] = useState("");

    const getOneCustomer = () =>{
        if(customerId!==0){
            getCustomerById(customerId)
            .then((resp)=>{
                setCustomerNom(resp.data.nom)
                setCustomerCode(resp.data.code)
            }).catch((error)=>{
                setCustomerNom("")
                setCustomerCode("")
            })
        }
    }

    useEffect(()=>{
        getCustomers()
        getOneCustomer()
    },[customerId,setCustomerId])

    const [montantVerse, setMontantVerse] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [statutPayment, setStatutPayment]=useState("au comptant");
    const [methodPayment, setMethodPayment]= useState("cash");
    const [statutExpedition, setStatutExpedition] =useState("sur place");
    const [note, setNote] = useState("")

    // Printer
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    // Dialog
    const [dialog, setDialog] = useState({
        icon:'',
        title:'',
        message: '',
        isOpen: false
    }) 
    
    // Ce qu'on doit faire selon le choix de la Boite de dialog
    const confirmeDiolog = (choose) =>{
        if(choose){
            handlePrint()
            props.updateCart([])
            props.setIsOpen(false)
            setDialog('', '', false)
        }else{
            setDialog('', '', false)
            props.updateCart([])
            props.setIsOpen(false)
        }
    }

    const handleValide = (e) =>{
        e.preventDefault();
        
        const due = (props.totalVente - montantVerse - discount>=0) ? (props.totalVente - montantVerse - discount) : 0;

        let order = {
            'noFacture':props.codeVente,
            'nomClient':customerNom,
            'statutPayment': due === props.totalVente ? "a credit" : (due>0 ? "balance" : statutPayment),
            'modePayment': statutPayment==="a credit" ? 'aucun' : methodPayment,
            'items': props.totalAmount,
            'montantVendu': props.totalVente,
            'montantVerser': statutPayment==="a credit" ? 0 : (montantVerse),
            'montantDue': statutPayment==="a credit" ? props.totalVente : due,
            'monnaie': (statutPayment==="a credit" ? 0 : ((montantVerse-(props.totalVente-discount))>=0 ? (montantVerse-(props.totalVente-discount)) : 0)),
            'discount': statutPayment==="a credit" ? 0 : discount,
            'statutExpedition': statutExpedition,
            'noteVente':note,
            'dateVente': props.date,
            'userId': getItem('userId'),
            'carts':props.cart
        }

        createOrder(order)
        .then((resp)=>{
            if(resp.data.message){
                setToast({toast, isOpen:true, isSuccess: true, message: resp.data.message, icon:icon_ok})
                
                setDialog({
                    icon:printer_icon,
                    title:'Impression',
                    message: 'Voulez-vous imprimer votre fiche?',
                    isOpen:true,
                })
            }else if(resp.data.error){
                setToast({toast, isOpen:true, isSuccess: false, message: resp.data.error, icon:icon_cancel})
            }else{
                setToast({toast, isOpen:true, isSuccess: false, message: resp.data.errors[0].message, icon:icon_cancel})
            }
        }).catch((error)=>{
            console.log(error)
        })
    }

  return (
    <div className='payment_container'>
        <div className="payment_modal">
            {/* Header */}
            <div className="payment_modal_header">
                <div>
                    <h4>Paiement</h4>
                </div>
                <div className='payment_modal_header_close' onClick={()=> props.setIsOpen(false)}>
                    x
                </div>
            </div>

            {/* Detail */}
            <div className='payment_modal_detail'>
                <h5>Details client</h5>
                
                <div className='payment_modal_detail_customer'>
                        {/* Group left payemnt detail */}
                        <div className="group_payment_modal_customer">
                            <div className="payment_modal_code_customer">
                                <h6>{ customerCode!=="" ? customerCode : "REF"}</h6>
                            </div>
                            <div className="payment_modal_about_customer">
                                <div className="payment_modal_about_customer_name">
                                    <h6>{customerNom!=="" ? customerNom : "Client sans Rendez vous"}</h6>
                                </div>
                                <div className="payment_modal_about_customer_orders">
                                    <h6>No Commande : {props.codeVente}</h6>
                                </div>
                            </div>
                        </div>
                        {/* Group Right payment detail */}
                        <div className="group_payment_modal_about_customer_date">
                            <h6>{props.date}</h6>
                        </div>
                </div>

                <div className="payment_modal_detail_order">
                    <h5>Details Transaction</h5>
                    
                    <div className='payment_modal_detail_order_container'>
                        {props.cart.map((item, index)=>(
                            <div key={index} className='payment_modal_detail_order_item'>
                                <div className="payment_modal_detail_order_item_detail">
                                    <h6>{item.categorie} {item.nom} / {item.variante}</h6>
                                    <h6>Prix: {(item.prixVente*item.quantity)+(item.prixDetail*item.quantityDetail)} $HT</h6>
                                </div>
                                <div className="payment_modal_detail_order_item_amount">
                                    <div className='body_cart_item_detail_qte'>
                                        {!(item.typeVente==='retail') && (!(item.typeVente==='simple') ? <h6>x{item.quantity}</h6> : <h6>x{item.quantity}</h6>)} 
                                        {item.quantityDetail!==0 && <h6>/ x{item.quantityDetail}</h6>}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className='payment_modal_detail_order_montant'>
                        <h6>Item</h6>
                        <h6>{props.totalAmount}x</h6>
                    </div>

                    <div className='payment_modal_detail_order_montant'>
                        <h6>Balance</h6>
                        <h6>{statutPayment==="a credit" ? props.totalVente : ((props.totalVente - montantVerse -discount)>0 ? (props.totalVente - montantVerse -discount) : 0)} $HT</h6>
                    </div>

                    <div className='payment_modal_detail_order_montant'>
                        <h6>Montant Versé</h6>
                        <h6>{statutPayment==="a credit" ? 0 : montantVerse} $HT</h6>
                    </div>

                    <div className='payment_modal_detail_order_montant'>
                        <h6>Rendu de monnaie</h6>
                        <h6>{statutPayment==="a credit" ? 0 : ((montantVerse-(props.totalVente-discount))>0 ? montantVerse-(props.totalVente-discount) : 0)} $HT</h6>
                    </div>
                    
                    <div className='payment_modal_detail_order_montant'>
                        <h5>Total vente</h5>
                        <h6> {props.totalVente} $HT</h6>
                    </div>
                </div>

            </div>

            {/* Transaction */}
            <div className='payment_modal_transaction'>
                <h5>Complétez votre transaction</h5>
                
                <form className='payment_form_group' onSubmit={handleValide}>

                    <div className="input_form_payment">
                        <label>Vos Client</label>
                        <select value={customerId} onChange={(e)=> setCustomerId(e.target.value)}>
                            <option></option>
                            {customers.map((item,index)=>(
                                <option key={index} value={item.id}>{item.nom} {item.prenom}</option>
                            ))}
                            <option value={0}>Sans Rendez-vous</option>
                        </select>
                    </div>

                    {customerCode === "" && 
                    <div className="input_form_payment">
                        <label>Nom Client</label>
                        <input type='text' placeholder='le nom du client' value={customerNom} onChange={(e)=> setCustomerNom(e.target.value)} required/>
                    </div>}

                    <div className="input_form_payment">
                        <label>Methode Paiement</label>
                        <select value={methodPayment} onChange={(e)=> setMethodPayment(e.target.value)} required>
                            <option></option>
                            <option value="cash">Cash</option>
                            <option value="virement">Virement telephonique</option>
                            <option value="credit card">Carte</option>
                        </select>
                    </div>

                    <div className="input_form_payment">
                        <label>Statut Paiement</label>
                        <select value={statutPayment} onChange={(e)=> setStatutPayment(e.target.value)} required>
                            <option></option>
                            <option value="au comptant">Au comptant</option>
                            <option value="a credit">A credit</option>
                        </select>
                    </div>

                    <div className="input_form_payment">
                        <label>Statut Expedition</label>
                        <select value={statutExpedition} onChange={(e)=> setStatutExpedition(e.target.value)} required>
                            <option></option>
                            <option value="sur place">Sur place</option>
                            <option value="a emporter">A emporter</option>
                        </select>
                    </div>

                    {statutPayment !== "a credit" && 
                        <div>
                            <div className="input_form_payment">
                                <label>Montant Versé</label>
                                <input type='number' value={montantVerse} placeholder='0.0 $HT' onChange={(e)=> setMontantVerse(e.target.value)}/>
                            </div>
                            <div className="input_form_payment">
                                <label>Discount</label>
                                <input type='number' value={discount} placeholder='0.0 $HT' onChange={(e)=> setDiscount(e.target.value)}/>
                            </div>
                        </div>
                    }

                    <div className="input_form_payment">
                        <label>Note de vente</label>
                        <textarea placeholder='Information sur la vente' onChange={(e)=> setNote(e.target.value)}/>
                    </div> 

                    <button className='input_form_payment_button'>Valider Paiement</button>
                </form>
                
            </div>
        </div>

        {/* Pour le composant à printer */}
        <div className="printer" >
            <ComponentToPrint ref={componentRef} 
                cart={props.cart} totalVente={props.totalVente} 
                totalAmount={props.totalAmount} customer={customerNom} 
                date={props.date} codeVente={props.codeVente} 
                salesPerson={props.salesPerson.username} 
                montantVerser={montantVerse} monnaie={(montantVerse > (props.totalVente-discount)) ? montantVerse-(props.totalVente-discount) : 0}
                discount={discount}
                balance={(props.totalVente - montantVerse - discount)<=0 ? 0 : (props.totalVente - montantVerse - discount)} 
                inView={false}/>
        </div>
        
        <Modal onDialog={confirmeDiolog} dialog={dialog} setDialog={setDialog}/>
    </div>
  )
}

export default Payment