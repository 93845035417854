import React, { useContext, useEffect, useState } from 'react'
import './addExpense.css'
import { ToastContext } from '../../utils/contexts/context';
import { useNavigate, useParams } from 'react-router-dom';
import { createExpense, getExpenseById, updateExpense } from '../../utils/services/expenseServices';
import icon_ok from '../../assets/image/icon_ok.png'
import icon_cancel from '../../assets/image/icon_cancel.png'
import icon_expense from '../../assets/image/low_price.png'

function AddExpense() {

    const [toast, setToast] = useContext(ToastContext);
    
    const id_params = useParams();

    // Date vente 
    const today= new Date();
    const date=new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(today);

    const navigate = useNavigate();

    // Nombre aléatoire
    const randomNumberInRange = (min, max) =>{
        return Math.floor(Math.random()
                * (max - min + 1)) + min;
    }

    // Les states du depense
    const [noRef, setNoRef] = useState(`Ref-${randomNumberInRange(100,10000)}`)
    const [spender, setSpender]= useState('')
    const [amount, setAmount] = useState(0)
    const [report, setReport] = useState('')
    const [dateExpense, setDateExpense] = useState(date)

    // Si on fait appel a ce composant pour une modification
    useEffect(()=>{
        getExpense()
    },[])

    const getExpense=()=>{
        if(id_params.id>0){
            getExpenseById(id_params.id)
            .then((resp)=>{
                setNoRef(resp.data.no_reference)
                setSpender(resp.data.spender)
                setAmount(resp.data.amount)
                setReport(resp.data.expense_report)
                setDateExpense(resp.data.date_expense)
            }).catch((error)=>{
                console.log(error)
            })
        }
        
    }

    const handleSave = (e) =>{
        e.preventDefault();

        let dataExpense = {
            'no_reference': noRef,
            'spender': spender,
            'amount':amount,
            'expense_report':report,
            'date_expense': dateExpense 
        }
       
        if(id_params.id>0){
            updateExpense(id_params.id, dataExpense)
            .then((resp)=>{
                if(resp.data.message){
                    setToast({toast, isOpen:true, isSuccess: true, message: resp.data.message, icon:icon_ok})
                    navigate('/expenses')
                }else{
                    setToast({toast, isOpen:true, isSuccess: false, message: "Modification echoué", icon:icon_cancel})
                }
            }).catch((error)=>
                console.log(error)
            ) 
        }else{
            createExpense(dataExpense)
            .then((resp)=>{
                if(resp.data.message){
                    setToast({toast, isOpen:true, isSuccess: true, message: resp.data.message, icon:icon_ok})
                    navigate('/expenses')
                }else{
                    setToast({toast, isOpen:true, isSuccess: false, message: "Enregistrement echoué", icon:icon_cancel})
                }
            }).catch((error)=>
                console.log(error)
            )
        }
    }

  return (
    <div className='expense_container'>
        <div className="header_container">
            <div className="div">
                <img src={icon_expense} alt='icon'/>
                <h4>{id_params.id>0 ? "Mise à Jour" : 'Nouvelle Dépense'}</h4>
            </div>
        
            <h6>{id_params.id>0 ? "Modifier les propriétés d'une dépense " : "Ajouter nouvelle dépense"}</h6>
        </div>

        <div className="body_form">

            <form className="form_container" onSubmit={handleSave}>

                <div className="inputs_form_container">
                        <div className="input_form">
                            <label>Date dépenses</label>
                            <input type='text' value={dateExpense} onChange={(e)=> setDateExpense(e.target.value)} disabled/>
                        </div>

                        <div className="input_form">
                            <label>No Reference</label>
                            <input type='text' value={noRef} placeholder='Ref' onChange={(e)=> setNoRef(e.target.value)} disabled/>
                        </div>

                        <div className="input_form">
                            <label>Dépenses pour</label>
                            <input type='text' value={spender} onChange={(e)=> setSpender(e.target.value)} placeholder="Dépenses Faites au nom de ..." required/>
                        </div>

                        <div className="input_form">
                            <label>Montant dépenses</label>
                            <input type='number' value={amount} onChange={(e)=> setAmount(e.target.value)} placeholder='0.0 $HT' required/>
                        </div>

                        <div className="input_form">
                            <label>Note de frais</label>
                            <textarea placeholder='Desciption' value={report} onChange={(e)=> setReport(e.target.value)} required/>
                        </div>
                </div>

                <div className="button_valide_form">
                    <button onClick={()=> navigate('/expenses')}>Annuler</button>
                    <button>Sauvegarder</button>
                </div>

            </form>

        </div>

    </div>
  )
}

export default AddExpense