import axios from 'axios'
import {jwtDecode} from 'jwt-decode'
import {getItem, addItem, removeItem} from './localStorage'
import {env} from '../environments/environment'

const baseUrl = env;

export function hasAuthenticated(){
    const token = getItem('authtoken')
    const IsValid = token ? tokenIsValid(token) : false; 
    if(IsValid === false){
        removeItem('authtoken')
    }
    
    return IsValid;
}

export function login(credentials){
    return axios
        .post(`${baseUrl}/auth/login`,credentials)
        .then(resp => {
            if(resp.data.token){
                addItem('authtoken', resp.data.token);
                addItem('userId', resp.data.userID)
                return true
            }else{
                alert(resp.data.message)
                return false
            }
        }).catch((error)=>{
            alert(error.response.data.message)
        })
}

export function logout(){
    removeItem('authtoken')
}

function tokenIsValid(token){
    const {exp} = jwtDecode(token);
    if(exp*1000 > new Date().getTime()){
        return true
    }

    return false
}