import axios from 'axios';
import { getItem } from './localStorage';
import {env} from '../environments/environment'

const urlApi = env;

const token = getItem('authtoken')

export function getAllArticles(){
    return axios.get(`${urlApi}/articles/all`, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    })
}

export function getBestArticles(){
    return axios.get(`${urlApi}/articles/`, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    })
}

export function createArticle(article){
    return axios.post(`${urlApi}/articles/create`, article, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    })
}

export function getArticleById(id){
    return axios.get(`${urlApi}/articles/` + id, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    });
}

export function updateArticle(id, article){
    return axios.put(`${urlApi}/articles/` +id, article, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    });
}

export function deleteArticle(id){
    return axios.delete(`${urlApi}/articles/remove/` + id, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    });
}