import React from 'react'
import error_icon from '../../assets/image/error.png'
import './error.css'

function Error() {
  return (
    <div className='error_container'>
      <div>
          <img src={error_icon} alt='error'/>
          <h6>Page introuvable</h6>
      </div>
    </div>
  )
}

export default Error