import React from 'react'
import './card.css'
import defaultImage from '../../assets/image/default.png'

function Card(props) {
  return props.suiviStock ? ( 
    props.itemsPerSet>0 ?
    ( !(props.stockDetail===0 && props.enStock===0) &&
      (<div className='card_container'>
        <div className="card_cover">
            <img src={props.imageUrl === "[object Object]" ? defaultImage : props.imageUrl} alt='cover' />
        </div>
        <div className="card_body">
            <h4 className="card_title_prod">{props.title}</h4>
            <h4 className="card_desc_prod">{props.categorie}</h4>
            <h5 className="card_desc_prod">{props.variante}</h5>
            {props.suiviStock && 
              <div className='card_desc_stock'>
                <h6 className="card_desc_prod">Stock: {props.enStock}</h6>
                {props.itemsPerSet>0 && <h6 className="card_desc_prod"> / Détail ({props.stockDetail})</h6>}
              </div>
            }
            <div className="card_price_prod">
                <h6>{props.price} $HT{props.stockDetail>0 && <span style={{fontSize: '.75em'}}> / {props.retailPrice} $HT</span>}</h6>
                <div className="button_card">
                    
                </div>
            </div>
        </div>
    </div>)
    ) : (props.enStock>0 && 
      (<div className='card_container'>
          <div className="card_cover">
              <img src={props.imageUrl === "[object Object]" ? defaultImage : props.imageUrl} alt='cover' />
          </div>
          <div className="card_body">
              <h4 className="card_title_prod">{props.title}</h4>
              <h4 className="card_desc_prod">{props.categorie}</h4>
              <h5 className="card_desc_prod">{props.variante}</h5>
              {props.suiviStock && 
                <div className='card_desc_stock'>
                  <h6 className="card_desc_prod">Stock: {props.enStock}</h6>
                  {props.itemsPerSet>0 && <h6 className="card_desc_prod"> / Détail ({props.stockDetail})</h6>}
                </div>
              }
              <div className="card_price_prod">
                  <h6>{props.price} $HT{props.stockDetail>0 && <span style={{fontSize: '.75em'}}> / {props.retailPrice} $HT</span>}</h6>
                  <div className="button_card">
                      
                  </div>
              </div>
          </div>
      </div>)
      )
  ) : 
  (
    <div className='card_container'>
      <div className="card_cover">
          <img src={props.imageUrl === "[object Object]" ? defaultImage : props.imageUrl} alt='cover' />
      </div>
      <div className="card_body">
          <h4 className="card_title_prod">{props.title}</h4>
          <h4 className="card_desc_prod">{props.categorie}</h4>
          <h5 className="card_desc_prod">{props.variante}</h5>
          
          <div className="card_price_prod">
              <h6>{props.price} $HT</h6>
          </div>
      </div>
    </div>
  )
}

export default Card