import axios from 'axios';
import { getItem } from './localStorage';
import {env} from '../environments/environment'

const urlApi = env;

const token = getItem('authtoken')

export function getAllSuppliers(){
    return axios.get(`${urlApi}/suppliers/all`, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    })
}

export function createSupplier(supplier){
    return axios.post(`${urlApi}/suppliers/create`, supplier, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    })
}

export function getSupplierById(id){
    return axios.get(`${urlApi}/suppliers/` + id, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    });
}

export function updateSupplier(id, supplier){
    return axios.put(`${urlApi}/suppliers/` +id, supplier, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    });
}

export function deleteSupplier(id){
    return axios.delete(`${urlApi}/suppliers/remove/` + id, {
        headers:{
            'Authorization' : `Bearer ${token}`
        }
    });
}